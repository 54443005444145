import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { Redirect, RouteComponentProps } from 'react-router';
import { LOGIN_WITH_TOKEN_REQUEST } from '../actions/login.action';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import { FAILURE, SUCCESS, UNCALLED } from '../constants/store.constants';
import { IPaymentRedirectQueryParams } from '../pages/PaymentRedirectPage/PaymentRedirectPage.types';

const LoginWithTokenContainer: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { tokenRequest, isLogged }: LoginState = useSelector(selectLogin);
  const { registrationToken }: IPaymentRedirectQueryParams = queryString.parse(location.search);

  useEffect(() => {
    if (!isLogged && registrationToken && tokenRequest.status === UNCALLED) {
      dispatch(LOGIN_WITH_TOKEN_REQUEST({ registrationToken }));
    }
  }, [dispatch, tokenRequest, isLogged, registrationToken]);

  if (!registrationToken) {
    return null;
  }

  if (process.env.REACT_APP_GOOGLE_GA_KEY) {
    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id: registrationToken,
      name: '365',
      sku: '1',
      price: '39.00',
      category: 'plan',
      quantity: '1'
    });
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: registrationToken,
      revenue: '39.00'
    });
    ReactGA.plugin.execute('ecommerce', 'send', '');
    ReactGA.plugin.execute('ecommerce', 'clear', '');
  }

  if (isLogged) {
    return <Redirect to={`/?signUpStatus=${SUCCESS}`} />;
  }

  if (tokenRequest.status === FAILURE) {
    return <Redirect to={`/?signUpStatus=${FAILURE}`} />;
  }

  return null;
};

export default LoginWithTokenContainer;
