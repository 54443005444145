import { string, object, number, InferType } from 'yup';

export interface IGetContentByKey {
  language: string;
  type: string;
}

export const ChaptersAttributesSchema = object().shape({
  page: number(),
  title: string(),
  l1: number(),
  l2: number()
});

export type TChapters = InferType<typeof ChaptersAttributesSchema>;

export const PagesAttributesSchema = object().shape({
  img: string(),
  vid: string(),
  page: number(),
  sort: number(),
  text: string(),
  link: string()
});

export type TPages = InferType<typeof PagesAttributesSchema>;

export interface IContent {
  chapters: TChapters[];
  pages: TPages[];
}
