import { put, select, takeEvery } from 'redux-saga/effects';
import { firebaseApp } from '../config/firebase.config';
import {
  FIREBASE_AUTH_FAILURE,
  FIREBASE_AUTH_SUCCESS,
  GET_TOKEN_FAILURE,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  SET_DEVICE_CHECKED,
  SET_DEVICE_UID,
  UPDATE_DEVICE_UID_FAILURE,
  UPDATE_DEVICE_UID_REQUEST,
  UPDATE_DEVICE_UID_SUCCESS
} from '../actions/auth.action';
import { IRootState } from '../reducer';
import { SET_IS_LOGGED } from '../actions/login.action';
import { getUserData, setDeviceId } from './auth.api';
import { DEVICE_UID, FB_TOKEN, TOKEN } from '../constants/storageKeys';

const getFirebaseToken = ({ auth }: IRootState) => auth.firebaseToken;
const getToken = ({ auth }: IRootState) => auth.token;
const getDeviceUid = ({ auth }: IRootState) => auth.deviceUid;

function* setDeviceUid(deviceUid: string) {
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : null;

  if (userId && deviceUid) {
    yield put(UPDATE_DEVICE_UID_REQUEST());
    try {
      yield setDeviceId(userId, deviceUid);
      localStorage.setItem(DEVICE_UID, deviceUid);
      yield put(UPDATE_DEVICE_UID_SUCCESS());
    } catch (e) {
      yield put(UPDATE_DEVICE_UID_FAILURE());
    }
  }
}

function* checkDevice() {
  const token = yield select(getToken);
  const deviceUid = yield select(getDeviceUid);
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : null;

  if (deviceUid) {
    yield setDeviceUid(deviceUid);
    yield put(SET_DEVICE_CHECKED(true));
  } else if (token && userId) {
    const userData = yield getUserData(userId);

    const deviceUidFromFB = userData.deviceUid;
    const deviceUidFromLS = localStorage.getItem(DEVICE_UID);

    if (deviceUidFromFB !== deviceUidFromLS) {
      yield put(SET_IS_LOGGED({ isLogged: false }));
      yield put(GET_TOKEN_REQUEST());
    } else {
      yield put(SET_DEVICE_UID(deviceUidFromFB));
      yield put(SET_DEVICE_CHECKED(true));
    }
  } else {
    yield put(SET_DEVICE_CHECKED(true));
  }
}

function* firebaseAuth() {
  try {
    const firebaseToken = yield select(getFirebaseToken);
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : null;

    if (!userId) {
      yield firebaseApp.auth().signInWithCustomToken(firebaseToken);
    }

    yield put(FIREBASE_AUTH_SUCCESS());
    yield checkDevice();
  } catch (e) {
    yield put(FIREBASE_AUTH_FAILURE(e));
  }
}

function* getTokens() {
  try {
    const firebaseTokenFromLS: string | null = localStorage.getItem(FB_TOKEN);
    const tokenFromLS: string | null = localStorage.getItem(TOKEN);

    if (firebaseTokenFromLS) {
      yield put(
        GET_TOKEN_SUCCESS({
          token: tokenFromLS,
          firebaseToken: firebaseTokenFromLS
        })
      );
    } else {
      yield put(GET_TOKEN_FAILURE('not found'));
    }
  } catch (e) {
    yield put(GET_TOKEN_FAILURE(e));
  }
}

function* authSaga() {
  yield takeEvery('GET_TOKEN_REQUEST', getTokens);
  yield takeEvery('FIREBASE_AUTH_REQUEST', firebaseAuth);
}

export default authSaga;
