import React from 'react';
import Avatar from '../../ui-components/Avatar/Avatar';
import { InstructorCardProps } from './InstructorCard.types';
import styles from './InstructorCard.module.css';

const InstructorCard: React.FC<InstructorCardProps> = ({ instructor, hidden }) => {
  if (!instructor) {
    return null;
  }

  return (
    <div className={styles.instructorInfo} hidden={hidden}>
      <div className={styles.instructorContent}>
        <Avatar alt={instructor.fullName} src={instructor.photo} />
        <div className={styles.instructorSchoolInfo}>
          <span className={styles.userName}>{instructor.fullName}</span>
          <span className={styles.userInfo}>{instructor.school}</span>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>Adresse</div>
          <div className={styles.instructorValue}>{instructor.address}</div>
        </div>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>Email</div>
          <div className={styles.instructorValue}>{instructor.email}</div>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>Adresse</div>
          <div className={styles.instructorValue}>{instructor.address}</div>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>Telefon</div>
          <div className={styles.instructorValue}>{instructor.phone}</div>
        </div>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>Mobile</div>
          <div className={styles.instructorValue}>{instructor.mobile}</div>
        </div>
      </div>
      <div className={styles.instructorContact}>
        <div className={styles.contactItem}>
          <div className={styles.instructorLabel}>Email</div>
          <div className={styles.instructorValue}>{instructor.email}</div>
        </div>
      </div>
    </div>
  );
};

export default InstructorCard;
