import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { GET_THEORY_REQUEST, CHANGE_THEORY_MATCH_PARAMS } from '../actions/theory.action';
import { IRootState } from '../reducer';
import { IChapters } from '../reducer/theory.types';
import Ads from '../components/Ads/Ads';
import { TheoryCard } from '../components/TheoryCard/TheoryCard';
import { TheoryTopic } from '../components/TheoryTopic/TheoryTopic';
import { TheoryModalNotLogged } from '../components/TheoryModalNotLogged/TheoryModalNotLogged';
import { ITheoryByTopicProps } from './TheoryByTopicContainer.types';
import history from '../router/history';
import { Loader } from '../ui-components/Loader/Loader';
import { RequestStatusType } from '../reducer/fetch.types';
import { PENDING, UNCALLED } from '../constants/store.constants';
import { selectLanguage } from '../reducer/user.selectors';
import styles from './TheoryByTopicContainer.module.css';

const TheoryByTopicContainer: React.FC<ITheoryByTopicProps> = ({ page }: ITheoryByTopicProps) => {
  const dispatch = useDispatch();
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const chapters = useSelector(({ theory }: IRootState) => theory.theory.chapters);
  const language = useSelector(selectLanguage);
  const requestStatus: RequestStatusType = useSelector(({ theory }: IRootState) => theory.theoryRequest.status);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(GET_THEORY_REQUEST({ language }));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(CHANGE_THEORY_MATCH_PARAMS({ page }));
  }, [dispatch, page]);

  const handleChangePathname = useCallback(
    (pathname: string) => {
      if (isLogged) {
        history.push(`/${language + pathname}`);
      } else {
        setShow(true);
      }
    },
    [isLogged, language]
  );

  const handleHideModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  if (requestStatus === 'failure') {
    return <div>Error</div>;
  }

  return (
    <div>
      <Loader show={requestStatus === PENDING || requestStatus === UNCALLED} />
      {!isLogged ? (
        <>
          <Ads />
          <div className="topicListNotLogged">
            <div className={styles.topicList}>
              <Container>
                <Row>
                  <Col>
                    <div className={styles.topicListContent}>
                      <TheoryCard handleChangePathname={handleChangePathname} />
                      <div className={styles.theoryTopicList}>
                        {chapters.map((chapter: IChapters, index: number) => (
                          <TheoryTopic
                            onChangePathname={handleChangePathname}
                            pathname={`/theory/topics/${chapter.page}`}
                            content={`${index + 1}. ${chapter.title}`}
                            key={chapter.title}
                          />
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="topicListLogged">
            <div className={styles.topicList}>
              <Container>
                <Row>
                  <Col>
                    <div className={styles.topicListContent}>
                      <TheoryCard handleChangePathname={handleChangePathname} />
                      <div className={styles.theoryTopicList}>
                        {chapters.map((chapter: IChapters, index: number) => (
                          <TheoryTopic
                            onChangePathname={handleChangePathname}
                            pathname={`/theory/topics/${chapter.page}`}
                            content={`${index + 1}. ${chapter.title}`}
                            key={chapter.title}
                          />
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <Ads />
        </>
      )}
      <TheoryModalNotLogged show={show} onHideModal={handleHideModal} currentLanguage={language} />
    </div>
  );
};

export default TheoryByTopicContainer;
