import { put, takeEvery, select, call } from 'redux-saga/effects';
import { GET_CONTENT_FAILURE, GET_CONTENT_SUCCESS } from '../actions/content.action';
import { getContent } from './content.api';
import { IRootState } from '../reducer';

const getContentStore = ({ content }: IRootState) => content.content;
const getLanguage = ({ content }: IRootState) => content.language;
const getType = ({ content }: IRootState) => content.type;

function* getList(action) {
  const contentState = yield select(getContentStore);
  const language = yield select(getLanguage);
  const type = yield select(getType);
  try {
    if (contentState.pages.length === 0 || language !== action.payload.language || type !== action.payload.type) {
      const response = yield call(getContent, action.payload);
      yield put(GET_CONTENT_SUCCESS(response));
    } else {
      yield put(GET_CONTENT_SUCCESS({ content: contentState, language }));
    }
  } catch (e) {
    yield put(GET_CONTENT_FAILURE(e.message));
  }
}

function* contentSaga() {
  yield takeEvery('GET_CONTENT_REQUEST', getList);
}

export default contentSaga;
