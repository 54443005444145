import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { IContentTopicProps } from './ContentTopic.types';
import styles from './ContentTopic.module.css';

export const ContentTopic: React.FC<IContentTopicProps> = ({
  onChangePathname,
  issub,
  isactive,
  content,
  pathname,
  highlight = false
}: IContentTopicProps) => {
  const handleChange = useCallback(() => {
    onChangePathname(pathname);
  }, [onChangePathname, pathname]);

  return (
    <div className={classNames({ [styles.active]: isactive, [styles.highlighted]: highlight }, styles.contentListItem)}>
      <Button
        className={issub ? styles.topicsListSubItemBtn : styles.topicsListItemBtn}
        variant="link"
        onClick={handleChange}
      >
        {content}
        <i className="icon-arrow" />
      </Button>
    </div>
  );
};
