import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';
import styles from './HomeCarousel.module.css';

const HomeCarousel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Carousel className={styles.homeSlider}>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.fromCHF.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.fromCHF.text')}</p>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.everythingLogin.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.everythingLogin.text')}</p>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.sliderTitle}>{t('homePage.slides.lngRules.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.lngRules.textLng')}</p>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
