import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './VipCardAccordion.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const VipCardAccordion: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.vipCardMobile}>
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="vip">
            <span className={styles.cardTitle}>{t('homePage.vipCard.vipCard')}</span>
            <div className={styles.accordionBtn}>
              <i className="icon-arrow" />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="vip">
            <Card.Body>
              <LinkWrapper className="linkBtn" to="/code">
                {t('homePage.btn.redeemCode')}
                <i className="icon-arrow" />
              </LinkWrapper>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default VipCardAccordion;
