import React from 'react';
import { useTranslation } from 'react-i18next';
import VipCard from '../VipCard/VipCard';
import Market from '../Market/Market';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './VipContent.module.css';

const ecodrive = imageAbsolutePath('ecodrive-logo.svg');

const VipContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.sectionVipContent}>
      <div className={styles.sectionVipItem}>
        <div className="h3">{t('homePage.vipCard.title')}</div>
        <VipCard />
      </div>
      <div className={styles.sectionVipItem}>
        <div className="h3">{t('homePage.readyToLearn.title')}</div>
        <div className={styles.storeBtnList}>
          <Market src={ecodrive} />
        </div>
      </div>
    </div>
  );
};

export default VipContent;
