import React, { useCallback, useEffect, useState } from 'react';
import uuidv4 from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { IRootState } from '../../reducer';
import { IExamResultContainerProps } from './ExamResultContainer.types';
import { CREATE_NEW_EXAM_REQUEST, RECOVERY_EXAM_REQUEST } from '../../actions/exam.action';
import ExamResultInfo from '../../components/ExamResultInfo/ExamResultInfo';
import { IExamResult, ILastExamInfo } from '../../reducer/exam.types';
import Congratulation from '../../components/Congratulation/Congratulation';
import { BtnList } from '../../components/BtnList/BtnList';
import { FAILURE, PENDING, SUCCESS } from '../../constants/store.constants';
import { Request } from '../../reducer/fetch.types';
import { Loader } from '../../ui-components/Loader/Loader';
import { selectLanguage } from '../../reducer/user.selectors';
import { IQuestion } from '../../reducer/question.types';
import QuestionChooseModalContainer from '../QuestionChooseModalContainer/QuestionChooseModalContainer';
import styles from './ExamResultContainer.module.css';

export const ExamResultContainer: React.FC<IExamResultContainerProps> = ({
  routeParams
}: IExamResultContainerProps) => {
  const [showQuestionWin, setShowQuestionWin] = useState(false);
  const { examRoute } = routeParams;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const examResult: IExamResult = useSelector(({ exam }: IRootState) => exam.examResult);
  const { history } = useReactRouter();
  const createExamRequest: Request = useSelector(({ exam }: IRootState) => exam.createExamRequest);
  const updateResultRequest: Request = useSelector(({ exam }: IRootState) => exam.updateResultRequest);
  const lastExamRequest: Request = useSelector(({ exam }: IRootState) => exam.lastExamRequest);
  const lastExam: ILastExamInfo = useSelector(({ exam }: IRootState) => exam.lastExam);
  const isLogged: boolean = useSelector(({ login }: IRootState) => login.isLogged);
  const [selectedExamUuid, setSelectedExamUuid] = useState(0);
  const currentLanguage = useSelector(selectLanguage);
  const questionList: IQuestion[] = useSelector(({ exam }: IRootState) => exam.examQuestionList);
  const offlineMode: boolean = useSelector(({ auth }: IRootState) => auth.offlineMode);

  const onShowQuestionWin = useCallback(() => {
    setShowQuestionWin(true);
  }, [setShowQuestionWin]);

  const onHideQuestionWin = useCallback(() => {
    setShowQuestionWin(false);
  }, [setShowQuestionWin]);

  const handleExamStart = useCallback(() => {
    const uuid = uuidv4();
    const { duration, questionCount, pointCount } = examResult;
    const examStartTime = Date.now();

    dispatch(CREATE_NEW_EXAM_REQUEST({ examStartTime, uuid, duration, questionCount, pointCount }));
    setSelectedExamUuid(uuid);
  }, [examResult, dispatch]);

  const handleQuestionChange = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history]
  );

  useEffect(() => {
    if (createExamRequest.status === SUCCESS && selectedExamUuid) {
      history.push(`/${currentLanguage}/exam/${selectedExamUuid}`);
    }
  }, [createExamRequest, history, selectedExamUuid, currentLanguage]);

  useEffect(() => {
    if ((updateResultRequest.status === SUCCESS && !examResult) || updateResultRequest.status === FAILURE) {
      history.push(`/${currentLanguage}/`);
    }
  }, [updateResultRequest, history, examResult, currentLanguage]);

  useEffect(() => {
    if (lastExam && lastExam.uid !== examRoute && lastExamRequest.status !== PENDING) {
      dispatch(RECOVERY_EXAM_REQUEST({ examId: examRoute }));
    }
  }, [dispatch, examRoute, lastExamRequest, lastExam]);

  return (
    <div className={styles.resultPage}>
      <Loader show={createExamRequest.status === PENDING || !examResult} />
      {examResult && (
        <Container>
          <div className={styles.resultPageContent}>
            <Congratulation isSuccess={examResult.truePointCount / examResult.pointCount >= 0.9} isLogged={isLogged} />
            <div className={styles.resultPageContentItem}>
              <ExamResultInfo examResult={examResult} currentLanguage={currentLanguage} />
              <BtnList>
                {isLogged ? (
                  <>
                    <Button variant="secondary" onClick={handleExamStart}>
                      {t('examResult.btn.newExam')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button as={Link} to={`/${currentLanguage}/offers`} variant="primary">
                      {t('examResult.btn.deals')}
                    </Button>
                  </>
                )}
                <Button
                  className="btn-outline-secondary"
                  disabled={offlineMode}
                  onClick={onShowQuestionWin}
                  variant="outline-secondary"
                >
                  {t('examResult.btn.mark')}
                </Button>
              </BtnList>
            </div>
          </div>
          <QuestionChooseModalContainer
            topicOpen
            finishedExam
            defaultRoute={`/${currentLanguage}/exam/${examRoute}`}
            onQuestionChange={handleQuestionChange}
            isExam
            questionList={questionList}
            show={showQuestionWin}
            onHide={onHideQuestionWin}
          />
        </Container>
      )}
    </div>
  );
};
