import React from 'react';
import cx from 'classnames';
import { ProgressBar, Jumbotron } from 'react-bootstrap';
import { ICockpitProgressProps } from './CockpitProgress.types';
import styles from './CockpitProgress.module.css';

const CockpitProgress: React.FC<ICockpitProgressProps> = ({ userStatistics }: ICockpitProgressProps) => {
  return userStatistics ? (
    <Jumbotron className={styles.jumbotron}>
      <div className={styles.progressBarContent}>
        <ProgressBar
          title={`${(userStatistics ? (userStatistics.progress * userStatistics.questionsCount) / 100 : 0).toFixed(
            0
          )} / ${userStatistics ? userStatistics.questionsCount : 0}`}
          className={styles.progressBar}
          now={userStatistics ? userStatistics.progress : 0}
        />
        <div
          title={`${userStatistics && userStatistics.status > -1 ? (userStatistics.status * 100).toFixed(0) : '0'} %`}
          className={cx(styles.status, {
            [styles.empty]: userStatistics && userStatistics.status === -1,
            [styles.bad]:
              userStatistics && userStatistics.status >= 0.0 && userStatistics && userStatistics.status < 0.4,
            [styles.middle]:
              userStatistics && userStatistics.status >= 0.4 && userStatistics && userStatistics.status < 1.0,
            [styles.good]: userStatistics && userStatistics.status === 1.0
          })}
        />
      </div>
    </Jumbotron>
  ) : (
    <></>
  );
};

export default CockpitProgress;
