import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducer';
import InstructorContainer from '../InstructorContainer/InstructorContainer';
import CategoryContainer from '../CategoryContainer/CategoryContainer';
import { RulesGroupContent } from '../../ui-components/RulesGroupContent/RulesGroupContent';
import styles from './QuestionsBgContainer.module.css';
import apptype from '../../utils/apptype';

const QuestionsBgContainer: React.FC = () => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const instructor = useSelector(({ user }: IRootState) => user.profile.drivingInstructor);

  if (isLogged && instructor) {
    return (
      <div className={styles.questionsBgContainer}>
        <RulesGroupContent>
          <div className={styles.imageBox}>
            <InstructorContainer src={apptype.path('questionsPageNotLogged.jpg')} hideForMobile />
          </div>
          <CategoryContainer />
        </RulesGroupContent>
      </div>
    );
  }
  return (
    <RulesGroupContent>
      <div className={styles.imageBox}>
        <InstructorContainer src={apptype.path('questionsPageNotLogged.jpg')} hideForMobile />
      </div>
      <CategoryContainer />
    </RulesGroupContent>
  );
};

export default QuestionsBgContainer;
