import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import { ILoginModalProps } from './LoginModal.types';
import styles from './LoginModal.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const LoginModal: React.FC<ILoginModalProps> = ({
  show,
  onHide,
  onRecovery,
  login,
  error,
  isLoading
}: ILoginModalProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const hasEmptyField = !email || !password;

  const onEmailChange = useCallback(
    e => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const onPasswordChange = useCallback(
    e => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      login(email, password);
    },
    [login, email, password]
  );

  const handleRecoveryLinkClick = useCallback(
    e => {
      e.preventDefault();
      onRecovery();
    },
    [onRecovery]
  );

  return (
    <Modal className="modalLogin" animation show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{t('loginModal.title')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              name="email"
              type="email"
              placeholder={t('loginModal.emailPlaceholder')}
              value={email}
              onChange={onEmailChange}
              autoComplete="email"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Control
              name="password"
              type="password"
              placeholder={t('loginModal.passwordPlaceholder')}
              value={password}
              onChange={onPasswordChange}
              autoComplete="password"
            />
          </Form.Group>
          {error && <p>{error}</p>}
          <Form.Group className={styles.formLinkBox}>
            <a href="/" className={styles.forgotPassLink} onClick={handleRecoveryLinkClick}>
              {t('loginModal.resetPassword')}
            </a>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button as={LinkWrapper} to="/offers" variant="link" onClick={onHide}>
            {t('loginModal.register')}
          </Button>
          <Button className={styles.actionBtn} variant="secondary" type="submit" disabled={isLoading || hasEmptyField}>
            {t('loginModal.btn')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default LoginModal;
