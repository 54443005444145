import { createAction } from 'redux-actions';

export const CHANGE_ANSWER = createAction('CHANGE_ANSWER');
export const CLEAR_ANSWER = createAction('CLEAR_ANSWER');
export const ADD_ANSWER_REQUEST = createAction('ADD_ANSWER_REQUEST');
export const ADD_ANSWER_SUCCESS = createAction('ADD_ANSWER_SUCCESS');
export const ADD_ANSWER_FAILURE = createAction('ADD_ANSWER_FAILURE');
export const CLEAR_ANSWER_BY_TOPIC_REQUEST = createAction('CLEAR_ANSWER_BY_TOPIC_REQUEST');
export const CLEAR_ANSWER_BY_TOPIC_FAILURE = createAction('CLEAR_ANSWER_BY_TOPIC_FAILURE');
export const CLEAR_ANSWER_BY_TOPIC_SUCCESS = createAction('CLEAR_ANSWER_BY_TOPIC_SUCCESS');
export const GET_ANSWER_FROM_FIREBASE_REQUEST = createAction('GET_ANSWER_FROM_FIREBASE_REQUEST');
export const GET_ANSWER_FROM_FIREBASE_FAILURE = createAction('GET_ANSWER_FROM_FIREBASE_FAILURE');
export const GET_ANSWER_FROM_FIREBASE_SUCCESS = createAction('GET_ANSWER_FROM_FIREBASE_SUCCESS');
export const CLEAR_CURRENT_ANSWER_REQUEST = createAction('CLEAR_CURRENT_ANSWER_REQUEST');
export const CLEAR_CURRENT_ANSWER_SUCCESS = createAction('CLEAR_CURRENT_ANSWER_SUCCESS');
export const CLEAR_CURRENT_ANSWER_FAILURE = createAction('CLEAR_CURRENT_ANSWER_FAILURE');
