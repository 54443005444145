import { handleActions } from 'redux-actions';
import { IRegisterState } from './register.types';
import { REGISTER_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_RESET_ERROR } from '../actions/register.action';
import { SUCCESS, PENDING, FAILURE, UNCALLED } from '../constants/store.constants';
import { defaultRequestStatus } from './fetch.types';

const defaultState: IRegisterState = {
  request: defaultRequestStatus
};

const reduces = handleActions(
  {
    [REGISTER_REQUEST]: state => ({
      ...state,
      request: { status: PENDING, error: null }
    }),
    [REGISTER_FAILURE]: (state, action) => ({
      ...state,
      request: { status: FAILURE, error: action.payload }
    }),
    [REGISTER_SUCCESS]: state => ({
      ...state,
      request: { status: SUCCESS, error: null }
    }),
    [REGISTER_RESET_ERROR]: state => ({
      ...state,
      request: { status: UNCALLED, error: null }
    })
  },
  defaultState
);

export default reduces;
