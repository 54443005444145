import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentByTopicContainer from '../../containers/ContentByTopicContainer';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import { IContentPageProps } from './DrivingskillsPage.types';
import styles from './DrivingskillsPage.module.css';
import { RulesGroupContent } from '../../ui-components/RulesGroupContent/RulesGroupContent';
import { RulesGroupItem } from '../../ui-components/RulesGroupItem/RulesGroupItem';
import PageDescription from '../../ui-components/PageDescription/PageDescription';
import apptype from '../../utils/apptype';

const DrivingskillsPage: React.FC<IContentPageProps> = ({ match }: IContentPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.theoryPage}>
      <Container>
        <Row>
          <Col>
            <div className={styles.theoryPageTopContent}>
              <RulesGroupContent>
                <div className={styles.imageBox}>
                  <InstructorContainer src={apptype.path('DrivingSkills.jpg')} hideForMobile />
                </div>
                <RulesGroupItem>
                  <PageDescription title={t('drivingskills.title')} description={t('drivingskills.descr')} />
                </RulesGroupItem>
              </RulesGroupContent>
            </div>
          </Col>
        </Row>
      </Container>
      <ContentByTopicContainer page={match.params.page} type="drivingskills" />
    </div>
  );
};

export default DrivingskillsPage;
