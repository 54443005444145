import { put, takeEvery } from 'redux-saga/effects';
import alasql from 'alasql';
import {
  GET_TOPICS_REQUEST,
  GET_TOPICS_FAILURE,
  GET_TOPICS_SUCCESS,
  GET_PROFILE_STATISTICS
} from '../actions/topic.action';
import { SET_USER_STATISTICS } from '../actions/user.action';
import { firebaseApp } from '../config/firebase.config';
import { TopicsAttributes } from '../reducer/topic.types';
import { TTopic, langTopicSchema } from './topic.schema';
import { getAnswerList } from './answer.api';
import seniortopic from '../db/seniortopic.json';
import privatetopic from '../db/privatetopic.json';
import businesstopic from '../db/businesstopic.json';

import apptype from '../utils/apptype';

const content = {
  senior: seniortopic,
  private: privatetopic,
  business: businesstopic
};

function* getTopicList(action) {
  try {
    const { language, cockpitPage } = action.payload;
    const topics: TopicsAttributes[] = [];
    const categoryForQuery = 'BAA1';

    const allTopicList: TTopic[] = alasql('SELECT * FROM ? WHERE lang = ? ORDER BY sortnr ASC', [
      content[apptype.type],
      language
    ]);

    allTopicList.forEach(topicItem => {
      const item = {
        title: topicItem.title,
        themeid: topicItem.themeid,
        sortnr: topicItem.sortnr,
        qCount: topicItem[categoryForQuery]
      };

      if (langTopicSchema.isValid(item)) {
        topics.push({
          questionsCount: topicItem[categoryForQuery],
          id: Number.isNaN(+topicItem.themeid) ? -1 : +topicItem.themeid,
          title: topicItem.title
        });
      }
    });

    if (cockpitPage) {
      yield put(GET_PROFILE_STATISTICS({ topics, categoryForQuery }));
    } else {
      yield put(GET_TOPICS_SUCCESS({ topics }));
    }
  } catch (e) {
    yield put(GET_TOPICS_FAILURE(e.message));
  }
}

function* getStatistics({ payload }) {
  const { currentUser } = firebaseApp.auth();
  const userId = currentUser ? currentUser.uid : '';
  const { questionsCount } = payload.topics.find(item => item.id === -1);

  const answers = yield getAnswerList(userId, payload.categoryForQuery);

  const topciIds = Object.keys(answers);

  // count answers
  const sum = topciIds.reduce((currentSum, topicid) => currentSum + Object.keys(answers[topicid]).length, 0);

  let checkedCount: number = 0;
  let correctCount: number = 0;

  // is all!
  const topicIds = Object.keys(answers);
  topicIds.forEach(topicId => {
    const answersKeyList: string[] = Object.keys(answers[topicId]);

    answersKeyList.forEach(answerKey => {
      if (typeof answers[topicId][answerKey].correct === 'boolean') {
        checkedCount += 1;
        if (answers[topicId][answerKey].correct) {
          correctCount += 1;
        }
      }
    });
  });

  yield put(
    SET_USER_STATISTICS({
      questionsCount,
      status: checkedCount ? correctCount / checkedCount : -1,
      progress: (sum * 100) / questionsCount
    })
  );
}

function* topicSaga() {
  yield takeEvery(GET_TOPICS_REQUEST, getTopicList);
  yield takeEvery(GET_PROFILE_STATISTICS, getStatistics);
}

export default topicSaga;
