import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { P3 } from '../../ui-components/P3/P3';
import CategoryIcon from '../../components/CategoryIcon/CategoryIcon';
import styles from './ImprintPage.module.css';

// Impressum
const ImprintPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.imprintPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('impressum.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <P3>{t('impressum.content1')}</P3>
            <P3>{t('impressum.content2')}</P3>
            <div className={styles.imprintList}>
              <CategoryIcon icon="icon-car" level="B" badge={false} />
            </div>
          </Col>
          <Col lg={6}>
            <P3>{t('impressum.content3.text1')}</P3>
            <P3>{t('impressum.content3.text2')}</P3>
            <P3>{t('impressum.content3.text3')}</P3>
            <P3>{t('impressum.content3.text4')}</P3>
            <P3>{t('info.tellabel')}</P3>
            <P3>{t('impressum.content3.text6')}</P3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ImprintPage;
