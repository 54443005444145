import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ModalByTopicContainer from './ModalByTopicContainer';
import { ITopicSelectorProps } from './TopicSelectrorContainer.types';
import { IRootState } from '../reducer';
import styles from './QuestionCardContainer/QuestionCardContainer.module.css';

export const TopicSelectorContainer: React.FC<ITopicSelectorProps> = ({ selectedTopicId }: ITopicSelectorProps) => {
  const [showTopicWin, setShowTopicWin] = useState(false);
  const topics = useSelector(({ topic }: IRootState) => topic.topics);
  const selectedTopic = topics.find(topicItem => {
    if (selectedTopicId === 'all') {
      return topicItem.id.toString() === '-1';
    }
    return topicItem.id.toString() === selectedTopicId.toString();
  });
  const btnTitle = selectedTopic ? selectedTopic.title : '';

  const handleShowTopicWin = useCallback(() => {
    setShowTopicWin(true);
  }, [setShowTopicWin]);

  const handleHideTopicWin = useCallback(() => {
    setShowTopicWin(false);
  }, [setShowTopicWin]);

  return (
    <div className={styles.questionCustomBtn}>
      <Button variant="link" className={styles.headerQuestionBtn} onClick={handleShowTopicWin}>
        {btnTitle}
        <i className="icon-arrow" />
      </Button>
      <ModalByTopicContainer show={showTopicWin} onHide={handleHideTopicWin} />
    </div>
  );
};
