import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { IRootState } from '../reducer';
import { IChapters } from '../reducer/content.types';
import { ContentTopic } from '../components/ContentTopic/ContentTopic';
import { selectLanguage } from '../reducer/user.selectors';
import { IContentTopicsListContainer } from './ContentTopicsListContainer.types';
import styles from './ContentTopicsListContainer.module.css';

const ContentTopicsListContainer: React.FC<IContentTopicsListContainer> = ({
  type,
  page
}: IContentTopicsListContainer) => {
  const chapters = useSelector(({ content }: IRootState) => content.content.chapters);
  const { history } = useReactRouter();
  const currentLanguage = useSelector(selectLanguage);

  const handleChangePathname = useCallback(
    (pathname: string) => {
      history.push(`/${currentLanguage}${pathname}`);
    },
    [history, currentLanguage]
  );

  // get chapter
  let curChapterNr: number = -1;
  chapters.map((chapter: IChapters) => {
    if (curChapterNr > 0) {
      return false;
    }

    if (+chapter.page === +page) {
      curChapterNr = chapter.l1;
    }
    return true;
  });

  const checkSubActive = (chapter: IChapters) => {
    if (curChapterNr < 0) {
      return true;
    }

    if (curChapterNr === chapter.l1) {
      return true;
    }

    return !chapter.l2;
  };

  return (
    <div className={styles.topicList}>
      {chapters
        .filter((chapter: IChapters) => checkSubActive(chapter))
        .map((chapter: IChapters) => (
          <ContentTopic
            onChangePathname={handleChangePathname}
            pathname={`/content/${type}/${chapter.page}`}
            content={`${chapter.l1}.${chapter.l2 ? chapter.l2 : ''} ${chapter.title}`}
            issub={!!chapter.l2}
            isactive={+page === +chapter.page || (!chapter.l2 && curChapterNr === chapter.l1)}
            key={chapter.title}
            highlight={chapter.page === 24 && type === 'drivingskills'}
          />
        ))}
    </div>
  );
};

export default ContentTopicsListContainer;
