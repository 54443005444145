import React, { useCallback } from 'react';
import { ITranslateProps } from './Translate.types';
import styles from '../Header/Header.module.css';

const LANG_ARRAY = ['/it', '/fr', '/de'];

const Translate: React.FC<ITranslateProps> = ({ children, lng, icon, routeProps }: ITranslateProps) => {
  const changeLng = useCallback((): void => {
    if (routeProps.history) {
      const pathnameWithoutLngPrefix = LANG_ARRAY.reduce(
        (accum, item) =>
          accum
            .slice(0, 3)
            .replace(item, '')
            .concat(accum.slice(3)),
        routeProps.location.pathname
      );
      routeProps.history.push({
        search: routeProps.location.search,
        hash: routeProps.location.hash,
        state: routeProps.location.state,
        pathname: `/${lng}${pathnameWithoutLngPrefix}`
      });
    }
  }, [routeProps, lng]);

  return (
    <button type="button" className={styles.button} onClick={changeLng}>
      {icon ? <img src={icon} alt="img" /> : children}
    </button>
  );
};
export default Translate;
