import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { GET_CONTENT_REQUEST, CHANGE_CONTENT_MATCH_PARAMS } from '../actions/content.action';
import { IRootState } from '../reducer';
import { IChapters } from '../reducer/content.types';
import Ads from '../components/Ads/Ads';
import { ContentTopic } from '../components/ContentTopic/ContentTopic';
import { IContentByTopicProps } from './ContentByTopicContainer.types';
import history from '../router/history';
import { Loader } from '../ui-components/Loader/Loader';
import { RequestStatusType } from '../reducer/fetch.types';
import { PENDING, UNCALLED } from '../constants/store.constants';
import { selectLanguage } from '../reducer/user.selectors';
import styles from './ContentByTopicContainer.module.css';

const ContentByTopicContainer: React.FC<IContentByTopicProps> = ({ page, type }: IContentByTopicProps) => {
  const dispatch = useDispatch();
  const chapters = useSelector(({ content }: IRootState) => content.content.chapters);
  const language = useSelector(selectLanguage);
  const requestStatus: RequestStatusType = useSelector(({ content }: IRootState) => content.contentRequest.status);

  useEffect(() => {
    dispatch(GET_CONTENT_REQUEST({ language, type }));
  }, [dispatch, language, type]);

  useEffect(() => {
    dispatch(CHANGE_CONTENT_MATCH_PARAMS({ page, type }));
  }, [dispatch, page, type]);

  const handleChangePathname = useCallback(
    (pathname: string) => {
      history.push(`/${language + pathname}`);
    },
    [language]
  );

  if (requestStatus === 'failure') {
    return <div>Error</div>;
  }

  return (
    <div>
      <Loader show={requestStatus === PENDING || requestStatus === UNCALLED} />
      <div className="topicListLogged">
        <div className={styles.topicList}>
          <Container>
            <Row>
              <Col>
                <div className={styles.topicListContent}>
                  <div className={styles.theoryTopicList}>
                    {chapters
                      .filter((chapter: IChapters) => !chapter.l2)
                      .map((chapter: IChapters) => (
                        <ContentTopic
                          onChangePathname={handleChangePathname}
                          pathname={`/content/${type}/${chapter.page}`}
                          content={`${chapter.l1}. ${chapter.title}`}
                          issub={false}
                          isactive={false}
                          key={chapter.title}
                        />
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Ads />
    </div>
  );
};

export default ContentByTopicContainer;
