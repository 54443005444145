import ExamCardPage from '../pages/ExamCardPage/ExamCardPage';
import ExamResultPage from '../pages/ExamResultPage/ExamResultPage';
import ExamPage from '../pages/ExamPage/ExamPage';
import TheoryPage from '../pages/TheoryPage/TheoryPage';
import DrivingskillsPage from '../pages/DrivingskillsPage/DrivingskillsPage';
import EcodrivePage from '../pages/EcodrivePage/EcodrivePage';
import ContentPage from '../pages/ContentPage/ContentPage';
import ImprintPage from '../pages/ImprintPage/ImprintPage';
import TermPage from '../pages/TermPage/TermPage';
import AsaPage from '../pages/AsaPage/AsaPage';
import QuestionCardPage from '../pages/QuestionCardPage/QuestionCardPage';
import QuestionPage from '../pages/QuestionPage/QuestionPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import AbonementPage from '../pages/AbonementPage/AbonementPage';
import DrivingSchoolPage from '../pages/DrivingSchoolPage/DrivingSchoolPage';
import VipCodePage from '../pages/VipCodePage/VipCodePage';
import CockpitLoggedPage from '../pages/CockpitLoggedPage/CockpitLoggedPage';
import DrivingInstructor from '../pages/DrivingInstructorPage/DrivingInstructor';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import PaymentRedirectPage from '../pages/PaymentRedirectPage/PaymentRedirectPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AnleitungPage from '../pages/AnleitungPage/AnleitungPage';
import { RoutesTypes } from './routes.types';
import NotFoundContainer from '../containers/NotFoundСontainer/NotFoundContainer';
import AboutPageContainer from '../containers/AboutPageContainer/AboutPageContainer';
import TheoryBookByTopicsPage from '../pages/TheoryBookByTopicsPage/TheoryBookByTopicsPage';
import ContentByTopicPage from '../pages/ContentByTopicPage/ContentByTopicPage';
import TheoryGlossaryPage from '../pages/TheoryGlossaryPage/TheoryGlossaryPage';
import OffersPage from '../pages/OffersPage/OffersPage';
import DrivingSchoolInfoPage from '../pages/DrivingSchoolInfoPage/DrivingSchoolInfoPage';
import MainPage from '../pages/MainPage/MainPage';
import Teacher3dPageContainer from '../containers/Teacher3dPageContainer/Teacher3dPageContainer';
import ExtendOffersPage from '../pages/ExtendOffersPage/ExtendOffersPage';
import ConfirmedPlanExtensionPage from '../pages/ConfirmedPlanExtensionPage/ConfirmedPlanExtensionPage';
import apptype from '../utils/apptype';

export const routes: RoutesTypes[] = [
  {
    path: '/',
    exact: true,
    component: MainPage
  },
  {
    path: '/exam/:examRoute/:id?',
    renderAfterLoad: true,
    component: ExamCardPage
  },
  {
    path: '/about',
    component: AboutPageContainer
  },
  {
    path: '/result/:examRoute',
    renderAfterLoad: true,
    component: ExamResultPage
  },
  {
    path: '/exam',
    renderAfterLoad: true,
    hideExpired: true,
    component: ExamPage
  },
  {
    path: '/theory/topics/:page',
    demoPathName: '/',
    renderAfterLoad: true,
    component: TheoryBookByTopicsPage
  },
  {
    path: '/theory/glossary/:page',
    renderAfterLoad: true,
    demoPathName: '/',
    component: TheoryGlossaryPage
  },
  {
    path: '/theory',
    hideExpired: true,
    component: TheoryPage
  },
  {
    path: '/drivingskills',
    hideExpired: true,
    component: DrivingskillsPage
  },
  {
    path: '/content/:type/:page',
    hideExpired: true,
    demoPathName: '/',
    renderAfterLoad: true,
    component: ContentByTopicPage
  },
  {
    path: '/ecodrive',
    hideExpired: true,
    component: EcodrivePage
  },
  {
    path: `/${apptype.type}`,
    hideExpired: true,
    component: ContentPage
  },
  {
    path: '/imprint',
    component: ImprintPage
  },
  {
    path: '/term',
    component: TermPage
  },
  {
    path: '/asa',
    component: AsaPage
  },
  {
    path: '/questions/:category/:topic/:id?',
    renderAfterLoad: true,
    hideExpired: true,
    component: QuestionCardPage
  },
  {
    path: '/questions',
    renderAfterLoad: true,
    hideExpired: true,
    component: QuestionPage
  },
  {
    path: '/contacts',
    component: ContactPage
  },
  {
    path: '/3dteacher',
    renderAfterLoad: true,
    component: Teacher3dPageContainer
  },
  {
    path: '/code',
    component: VipCodePage
  },
  {
    path: '/school',
    exact: true,
    component: DrivingSchoolPage
  },
  {
    path: '/school/:id',
    exact: true,
    component: DrivingSchoolInfoPage
  },
  {
    path: '/abonement',
    component: AbonementPage
  },
  {
    path: '/mycockpit',
    renderAfterLoad: true,
    demoPathName: '/',
    component: CockpitLoggedPage
  },
  {
    path: '/instructor',
    component: DrivingInstructor
  },
  {
    path: '/offers',
    component: OffersPage
  },
  {
    path: '/extend-offers',
    demoPathName: '/offers',
    component: ExtendOffersPage
  },
  {
    path: '/register',
    component: RegisterPage
  },
  {
    path: '/plan-extension',
    demoPathName: '/register',
    component: RegisterPage
  },
  {
    path: '/payment',
    component: PaymentRedirectPage
  },
  {
    path: '/confirmed-plan-extension',
    demoPathName: '/register',
    component: ConfirmedPlanExtensionPage
  },
  {
    path: '/faq',
    component: FaqPage
  },
  {
    path: '/anleitung',
    component: AnleitungPage
  },
  {
    path: '*',
    component: NotFoundContainer
  }
];
