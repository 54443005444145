import React, { useEffect, useCallback, useState, useRef, RefObject } from 'react';
import { Redirect } from 'react-router';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { FAILURE, PENDING, UNCALLED } from '../../constants/store.constants';
import { GET_PAYMENT_METHODS_REQUEST } from '../../actions/paymentMethod.action';
import { GET_OFFERS_REQUEST } from '../../actions/offer.action';
import { REGISTER_REQUEST, REGISTER_RESET_ERROR } from '../../actions/register.action';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import { Loader } from '../../ui-components/Loader/Loader';
import { LinkWrapper as Link } from '../../ui-components/LinkWrapper/LinkWrapper';
import { getErrorMessagesFromResponse } from '../../utils/fetch';
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod';
import PaymentConfirmation from '../../components/PaymentConfirmation/PaymentConfirmation';
import { IRegisterRequestPayload } from '../../saga/register.types';
import { InitRegistrationRequestPayload } from '../../components/RegisterForm/RegisterForm.constants';
import { selectRegister } from '../../reducer/register.selectors';
import { IRegisterState } from '../../reducer/register.types';
import { IOffersState, Offer } from '../../reducer/offer.types';
import { selectVoucher } from '../../reducer/voucher.selectors';
import { IVoucherState } from '../../reducer/voucher.types';
import { selectSchool } from '../../reducer/scool.selectors';
import { SchoolState } from '../../reducer/school.types';
import { TEACHER_ID } from '../../constants/storageKeys';
import { GET_SCHOOL_BY_ID_REQUEST } from '../../actions/school.action';
import { createSelectOfferById, selectOffer } from '../../reducer/offer.selectors';
import { selectPaymentMethods } from '../../reducer/paymentMethod.selectors';
import { IPaymentMethodState } from '../../reducer/paymentMethod.types';
import PlaceholderBox from '../../components/PlaceholderBox/PlaceholderBox';
import { IRegisterFormData } from '../../components/RegisterForm/RegisterForm.types';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';
import { PLAN_EXTENSION_REQUEST } from '../../actions/user.action';
import { IUserState } from '../../reducer/user.types';
import { selectLanguage, selectUser } from '../../reducer/user.selectors';
import { IUserPlanExtensionRequestPayload } from '../../saga/user.types';
import { IRegisterContainerProps, IRegisterContainerQueryParams } from './RegisterContainer.types';
import { isSSR } from '../../utils/utils';
import styles from './RegisterContainer.module.css';
import { P3 } from '../../ui-components/P3/P3';

const composeRegisterPayload = (
  formData: IRegisterFormData,
  planId: number,
  promo: string | null,
  type: string | null,
  subtype: string | null,
  voucherCode: string | null,
  teacherId: number | null
): IRegisterRequestPayload => ({
  ...formData,
  planId,
  promo,
  type,
  subtype,
  voucherCode,
  teacherId
});

const composeExtendPlanPayload = (
  planId: number,
  type: string | null,
  subtype: string | null,
  voucherCode: string | null
): IUserPlanExtensionRequestPayload => ({
  planId,
  type,
  subtype,
  voucherCode
});

const RegisterContainer: React.FC<IRegisterContainerProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguage);
  const { isLogged }: LoginState = useSelector(selectLogin);
  const { planExtensionRequest, profile }: IUserState = useSelector(selectUser);
  const { offer, promo }: IRegisterContainerQueryParams = queryString.parse(location.search);
  const [formData, setFormDate] = useState<IRegisterFormData>(InitRegistrationRequestPayload);
  const [payment, setPayment] = useState({ id: null, type: null, subtype: null });
  const [isPopupShow, setIsPopupShow] = useState(false);
  const { voucher }: IVoucherState = useSelector(selectVoucher);
  // eslint-disable-next-line
  const teacherId: number | null = isSSR()
    ? null
    : isLogged
    ? profile.teacherId || null
    : Number(voucher.teacherId) || Number(window.localStorage.getItem(TEACHER_ID)) || null;
  const { currentSchool, currentSchoolRequest }: SchoolState = useSelector(selectSchool);
  const { paymentMethodsList }: IPaymentMethodState = useSelector(selectPaymentMethods);
  const { request }: IRegisterState = useSelector(selectRegister);
  const currentOffer: Offer = useSelector(createSelectOfferById(Number(offer)));
  const { offerListRequest }: IOffersState = useSelector(selectOffer);
  const isVoucherPresent = Boolean(voucher.code && currentOffer && String(voucher.offerId) === String(currentOffer.id));
  const submitButtonRef = useRef(null) as RefObject<HTMLButtonElement>;
  const isIncludeAffiliates = voucher.affiliatesId;

  const priceClasses = cx(styles.itemValue, { [styles.affiliatePrice]: isIncludeAffiliates });
  const infoClasses = cx(styles.planInfo, { [styles.affiliateInfo]: isIncludeAffiliates });

  useEffect(() => () => dispatch(REGISTER_RESET_ERROR()), [dispatch]);

  useEffect(() => {
    if (offerListRequest.status === UNCALLED) {
      dispatch(
        GET_OFFERS_REQUEST({
          offersPromo: typeof promo === 'undefined' ? '' : promo
        })
      );
    }
  }, [dispatch, offerListRequest, promo]);

  useEffect(() => {
    if (offer) {
      dispatch(GET_PAYMENT_METHODS_REQUEST(offer));
    }
  }, [dispatch, offer]);

  useEffect(() => {
    if (teacherId && (!currentSchool || String(teacherId) !== String(currentSchool.teacherId))) {
      dispatch(GET_SCHOOL_BY_ID_REQUEST(teacherId));
    }
  }, [currentSchool, teacherId, dispatch]);

  const choicePaymentMethod = useCallback(paymentData => setPayment(paymentData), []);

  const handlePopupClose = () => setIsPopupShow(false);

  const handleFormSubmit = useCallback(
    (data: IRegisterFormData) => {
      setFormDate(data);

      if (isVoucherPresent) {
        dispatch(
          REGISTER_REQUEST(
            composeRegisterPayload(data, Number(voucher.offerId), null, null, null, voucher.code, teacherId)
          )
        );
      } else {
        setIsPopupShow(true);
      }
    },
    [isVoucherPresent, voucher, teacherId, dispatch]
  );
  const confirmRegister = useCallback(() => {
    setIsPopupShow(false);

    if (currentOffer.id && payment.type) {
      dispatch(
        REGISTER_REQUEST(
          composeRegisterPayload(
            formData,
            currentOffer.id,
            promo || null,
            payment.type,
            payment.subtype,
            null,
            teacherId
          )
        )
      );
    }
  }, [currentOffer, payment, formData, teacherId, promo, dispatch]);

  const handlePlanExtensionClick = useCallback(() => {
    dispatch(
      PLAN_EXTENSION_REQUEST(
        composeExtendPlanPayload(
          Number(voucher.offerId) || Number(currentOffer.id),
          payment.type || null,
          payment.subtype || null,
          voucher.code || null
        )
      )
    );
  }, [dispatch, payment, voucher, currentOffer]);

  const handleSubmitBtnClick = useCallback(() => {
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }, [submitButtonRef]);

  const validationErrorMessages: { [key: string]: string } = request.error
    ? getErrorMessagesFromResponse(request.error)
    : {};

  return offer ? (
    <div className={styles.registration}>
      <Container>
        <Row>
          <Col>
            <Link className="linkBtn" to="/offers">
              <i className="icon-arrow" />
              {t('registerForm.backOffers')}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.formTitle}>{t(isLogged ? 'registerForm.titleLogged' : 'registerForm.title')}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            {Object.values(validationErrorMessages)
              .filter(Boolean)
              .map(error => (
                <Alert key={error} variant="danger">
                  {error}
                </Alert>
              ))}
          </Col>
        </Row>
        <Row className={styles.formRegistration}>
          <Col lg={4}>
            <PlaceholderBox show={!currentOffer} height="200px">
              {currentOffer && (
                <div className={infoClasses}>
                  <div className={styles.planInfoItem}>
                    <p className={styles.itemLabel}>{t('registerForm.offer.name')}</p>
                    <p className={styles.itemValue}>{t(`plan.${currentOffer.title}`)}</p>
                  </div>
                  {isVoucherPresent && (
                    <div className={styles.planInfoItem}>
                      <p className={styles.itemLabel}>{t('registerForm.offer.voucherCode')}</p>
                      <p className={styles.itemValue}>{voucher.code}</p>
                    </div>
                  )}
                  <div className={styles.planInfoBox}>
                    <div className={styles.planInfoItem}>
                      <p className={styles.itemLabel}>{t('registerForm.offer.days')}</p>
                      <p className={styles.itemValue}>
                        {`${currentOffer.days}`}
                        &nbsp;
                        {t('registerForm.offer.day')}
                      </p>
                    </div>
                    <div className={styles.planInfoItem}>
                      <p className={styles.itemLabel}>{t('registerForm.offer.cost')}</p>
                      <p className={priceClasses}>{currentOffer.cost}</p>
                    </div>
                  </div>
                </div>
              )}
              {isLogged && isVoucherPresent && (
                <Button
                  variant="secondary"
                  onClick={handlePlanExtensionClick}
                  disabled={(!isVoucherPresent && payment.id === null) || planExtensionRequest.status === PENDING}
                >
                  {t('registerForm.redeemButton')}
                </Button>
              )}
            </PlaceholderBox>
            <PlaceholderBox show={Boolean(teacherId && currentSchoolRequest.status === FAILURE)} height="200px" />
            {teacherId && currentSchool && currentSchool.teacherId === teacherId && (
              <>
                <div className={cx(styles.planInfo, styles.planInfoTopOffset)}>
                  <div className={styles.planInfoItem}>
                    <p className={styles.itemLabel}>{t('registerForm.school.name')}</p>
                    <p className={styles.itemValue}>{currentSchool.teacherSchoolName}</p>
                  </div>
                  <div className={styles.planInfoItem}>
                    <p className={styles.itemLabel}>{t('registerForm.school.address')}</p>
                    <p className={styles.itemValue}>
                      {`${currentSchool.teacherAddress}, ${currentSchool.teacherCity}`}
                    </p>
                  </div>
                  <div className={styles.planInfoItem}>
                    <p className={styles.itemLabel}>{t('registerForm.school.coupon')}</p>
                    <p className={styles.itemValue}>
                      СHF
                      {currentSchool.teacherVoucherValue}
                    </p>
                  </div>
                </div>
                <div className={cx(styles.planInfo, styles.planInfoTopOffsetMobile)}>
                  <div className={styles.planInfoItem}>
                    <p className={styles.itemLabel}>{t('registerForm.school.name')}</p>
                    <P3>{`${currentSchool.teacherSchoolName},${currentSchool.teacherAddress}`}</P3>
                  </div>
                </div>
              </>
            )}
          </Col>
          {!isLogged && (
            <Col lg={4}>
              <RegisterForm
                submitButtonRef={submitButtonRef}
                onSubmit={handleFormSubmit}
                errorMessages={validationErrorMessages}
              />
            </Col>
          )}
          <Col lg={4}>
            {!isVoucherPresent && (
              <div className={styles.paymentList}>
                <p className="p3">{t('registerForm.payMethodsTitle')}</p>
                <ListGroup>
                  {paymentMethodsList.map(method => (
                    <PaymentMethod
                      key={method.id}
                      id={method.id}
                      img={method.img}
                      title={method.langs[currentLanguage].title}
                      type={method.type}
                      subtype={method.subtype}
                      choicePaymentMethod={choicePaymentMethod}
                      isActive={payment.id === method.id}
                    />
                  ))}
                </ListGroup>
              </div>
            )}
            {isLogged && !isVoucherPresent && (
              <Button
                variant="secondary"
                onClick={handlePlanExtensionClick}
                disabled={(!isVoucherPresent && payment.id === null) || planExtensionRequest.status === PENDING}
              >
                {t('registerForm.payButton')}
              </Button>
            )}
          </Col>
          <Col>
            {!isLogged && (
              <Button
                variant="secondary"
                type="button"
                onClick={handleSubmitBtnClick}
                disabled={(!isVoucherPresent && payment.id === null) || request.status === PENDING}
              >
                {isVoucherPresent ? t('registerForm.redeemButton') : t('registerForm.payButton')}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      <Loader show={request.status === PENDING || planExtensionRequest.status === PENDING} />
      <PaymentConfirmation show={isPopupShow} onHide={handlePopupClose} onConfirm={confirmRegister} />
    </div>
  ) : (
    <Redirect to={`${currentLanguage}/offers`} />
  );
};

export default RegisterContainer;
