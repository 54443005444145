import { IRootState } from './index';
import { IUserState } from './user.types';
import { Request } from './fetch.types';
import * as utils from '../utils/utils';

export const selectUser = ({ user }: IRootState): IUserState => user;

export const selectExpirePlaneDateTime = ({ user }: IRootState): string | undefined => user.profile.expirePlaneDateTime;
export const selectIsPlanExpired = (state: IRootState) => utils.isPlanExpired(selectExpirePlaneDateTime(state));

export const selectLanguage = (state: IRootState): string => selectUser(state).profile.language;
export const selectUseruserRequest = ({ user }: IRootState): Request => user.userRequest;
