import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentBookContainer from '../../containers/ContentBookContainer';
import ContentTopicsListContainer from '../../containers/ContentTopicsListContainer';
import Ads from '../../components/Ads/Ads';
import { IContentPageProps } from './ContentByTopicPage.types';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { THEORY_PAGE_ANCHOR } from '../../constants/anchors';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import styles from './ContentByTopicPage.module.css';

const ContentByTopicPage: React.FC<IContentPageProps> = ({ match }: IContentPageProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.theoryTopicPage} id={THEORY_PAGE_ANCHOR}>
        <Container>
          <Row>
            <Col>
              <LinkWrapper className={styles.linkBtn} to={`/${match.params.type}`}>
                <i className="icon-arrow" />
                {t(`${match.params.type}.title`)}
              </LinkWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.theoryTopicContent}>
                <ContentTopicsListContainer type={match.params.type} page={match.params.page} />
                <ContentBookContainer type={match.params.type} page={match.params.page} />
              </div>
            </Col>
          </Row>
        </Container>
        <div className={styles.mobileView}>
          <Container>
            <Row>
              <Col>
                <InstructorContainer />
              </Col>
            </Row>
          </Container>
        </div>
        <Ads />
      </div>
    </>
  );
};

export default ContentByTopicPage;
