import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IDemoFlagProps } from './DemoFlag.types';

const DemoFlag: React.FC<IDemoFlagProps> = ({ isHidden }: IDemoFlagProps) => {
  const { t } = useTranslation();

  return (
    <Nav.Link as={Link} to="/mycockpit" href="#mycockpit" className="demoVersion" hidden={isHidden}>
      <span className="demoText">{t('demoFlag.demoVersion')}</span>
    </Nav.Link>
  );
};

export default DemoFlag;
