import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { isSSR } from '../utils/utils';

const config = {
  apiKey: isSSR() ? 'AIzaSyDCyTuCYPaY-2RA1CCgktuJxyX9dORUHuU' : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: isSSR() ? 'rt24senior' : process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export const firebaseApp = firebase.initializeApp(config);

export const offlineMode = firebase.firestore().enablePersistence();

offlineMode.catch(err => {
  if (err.code === 'failed-precondition') {
    console.log(`Multiple tabs open, persistence can only be enabled in one tab at a a time.`);
  } else if (err.code === 'unimplemented') {
    console.log(`The current browser does not support all of the features required to enable persistence`);
  }
});

export const db = firebaseApp.firestore();
