import React from 'react';
import { Row, Col, Button, Jumbotron, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ICockpitTopPanelProps } from './CockpitTopPanel.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { P3 } from '../../ui-components/P3/P3';
import styles from './CockpitTopPanel.module.css';
import { getPlanDateCountLabel } from '../../utils/utils';
import apptype from '../../utils/apptype';

const CockpitTopPanel: React.FC<ICockpitTopPanelProps> = ({
  userName,
  selectedCategory,
  expirePlaneDateTime
}: ICockpitTopPanelProps) => {
  const { t } = useTranslation();
  const availablePlanDays = getPlanDateCountLabel(expirePlaneDateTime);

  return (
    <div className={styles.cockpitTopPanel}>
      <Container>
        <Row>
          <Col>
            <div className={styles.jList}>
              <Jumbotron className={styles.jumbotron}>
                <PageTitle>{`${t('cockpitTopPanel.hi.title')}, ${userName}`}</PageTitle>
                <P3>{t('cockpitTopPanel.hi.subtitle1')}</P3>
                <P3>{t('cockpitTopPanel.hi.subtitle2')}</P3>
                <div className={styles.mobileJumbotron}>
                  <Jumbotron className={styles.jumbotron}>
                    <div className={styles.panelTitle}>{t('cockpitTopPanel.duration.title')}</div>
                    <div className={styles.daysContent}>
                      <div className={styles.daysValue}>{availablePlanDays}</div>
                      <div className={styles.daysLabel}>{t('cockpitTopPanel.duration.days')}</div>
                    </div>
                  </Jumbotron>
                </div>
                <div className={styles.cardFooter}>
                  <Button as={LinkWrapper} variant="secondary" to={`/questions/${selectedCategory}/all`} hidden>
                    {t('cockpitTopPanel.hi.learn')}
                  </Button>
                </div>
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <img className="responsive-img" src={apptype.path('logo.svg')} alt="routinier24-logo" />
              </Jumbotron>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitTopPanel;
