import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { IOfferCardProps } from './OfferCard.types';
import styles from './OfferCard.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const OfferCard: React.FC<IOfferCardProps> = ({ offer, linkTo, currentLanguage }: IOfferCardProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <h3>{t(`plan.${offer.title}`)}</h3>
          <span className={styles.headerText}>{offer.cost}</span>
        </Card.Title>
        <Card.Text className="p3 pageText">{offer.langs[currentLanguage].description}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <span className={styles.footerDays}>{`${offer.days} ${t('offersPage.days')}`}</span>
        <LinkWrapper className="linkBtn" to={linkTo}>
          <span className={styles.continueText}>{t('offersPage.more')}</span>
          <i className="icon-arrow" />
        </LinkWrapper>
      </Card.Footer>
    </Card>
  );
};

export default OfferCard;
