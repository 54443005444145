import { put, takeEvery } from 'redux-saga/effects';
import { GET_OFFERS_REQUEST, GET_OFFERS_FAILURE, GET_OFFERS_SUCCESS } from '../actions/offer.action';
import { getOffers } from './offer.api';

function* getOfferList({ payload: offersPromo }) {
  try {
    const response = yield getOffers(offersPromo);
    yield put(GET_OFFERS_SUCCESS(response.payload.offers));
  } catch (e) {
    yield put(GET_OFFERS_FAILURE(e.message));
  }
}

function* offerSaga() {
  yield takeEvery(GET_OFFERS_REQUEST, getOfferList);
}

export default offerSaga;
