import {
  IGetContentByKey,
  ChaptersAttributesSchema,
  IContent,
  TChapters,
  TPages,
  PagesAttributesSchema
} from './content.schema';

// driving skills
import dspageDe from '../db/drivingskills_page_de.json';
import dspageFr from '../db/drivingskills_page_fr.json';
import dspageIt from '../db/drivingskills_page_it.json';
import dspageEn from '../db/drivingskills_page_en.json';

import dschapterDe from '../db/drivingskills_chapter_de.json';
import dschapterFr from '../db/drivingskills_chapter_fr.json';
import dschapterIt from '../db/drivingskills_chapter_it.json';
import dschapterEn from '../db/drivingskills_chapter_en.json';

// senior
import seniorpageDe from '../db/senior_page_de.json';
import seniorpageFr from '../db/senior_page_fr.json';
import seniorpageIt from '../db/senior_page_it.json';
import seniorpageEn from '../db/senior_page_en.json';

import seniorchapterDe from '../db/senior_chapter_de.json';
import seniorchapterFr from '../db/senior_chapter_fr.json';
import seniorchapterIt from '../db/senior_chapter_it.json';
import seniorchapterEn from '../db/senior_chapter_en.json';

// private
import privatepageDe from '../db/private_page_de.json';
import privatepageFr from '../db/private_page_fr.json';
import privatepageIt from '../db/private_page_it.json';
import privatepageEn from '../db/private_page_en.json';

import privatechapterDe from '../db/private_chapter_de.json';
import privatechapterFr from '../db/private_chapter_fr.json';
import privatechapterIt from '../db/private_chapter_it.json';
import privatechapterEn from '../db/private_chapter_en.json';

// business
import businesspageDe from '../db/business_page_de.json';
import businesspageFr from '../db/business_page_fr.json';
import businesspageIt from '../db/business_page_it.json';
import businesspageEn from '../db/business_page_en.json';

import businesschapterDe from '../db/business_chapter_de.json';
import businesschapterFr from '../db/business_chapter_fr.json';
import businesschapterIt from '../db/business_chapter_it.json';
import businesschapterEn from '../db/business_chapter_en.json';

// ecodrive
import ecodrivepageDe from '../db/ecodrive_page_de.json';
import ecodrivepageFr from '../db/ecodrive_page_fr.json';
import ecodrivepageIt from '../db/ecodrive_page_it.json';
import ecodrivepageEn from '../db/ecodrive_page_en.json';

import ecodrivechapterDe from '../db/ecodrive_chapter_de.json';
import ecodrivechapterFr from '../db/ecodrive_chapter_fr.json';
import ecodrivechapterIt from '../db/ecodrive_chapter_it.json';
import ecodrivechapterEn from '../db/ecodrive_chapter_en.json';

const chapters = {
  drivingskills: {
    de: dschapterDe,
    fr: dschapterFr,
    it: dschapterIt,
    en: dschapterEn
  },
  senior: {
    de: seniorchapterDe,
    fr: seniorchapterFr,
    it: seniorchapterIt,
    en: seniorchapterEn
  },
  private: {
    de: privatechapterDe,
    fr: privatechapterFr,
    it: privatechapterIt,
    en: privatechapterEn
  },
  business: {
    de: businesschapterDe,
    fr: businesschapterFr,
    it: businesschapterIt,
    en: businesschapterEn
  },
  ecodrive: {
    de: ecodrivechapterDe,
    fr: ecodrivechapterFr,
    it: ecodrivechapterIt,
    en: ecodrivechapterEn
  }
};

const pages = {
  drivingskills: {
    de: dspageDe,
    fr: dspageFr,
    it: dspageIt,
    en: dspageEn
  },
  senior: {
    de: seniorpageDe,
    fr: seniorpageFr,
    it: seniorpageIt,
    en: seniorpageEn
  },
  private: {
    de: privatepageDe,
    fr: privatepageFr,
    it: privatepageIt,
    en: privatepageEn
  },
  business: {
    de: businesspageDe,
    fr: businesspageFr,
    it: businesspageIt,
    en: businesspageEn
  },
  ecodrive: {
    de: ecodrivepageDe,
    fr: ecodrivepageFr,
    it: ecodrivepageIt,
    en: ecodrivepageEn
  }
};

export const getContent = async ({ language, type }: IGetContentByKey) => {
  const content: IContent = {
    chapters: [],
    pages: []
  };

  await Promise.all([
    Promise.all(
      chapters[type][language].map(async item => {
        const data = item as TChapters;
        if (await ChaptersAttributesSchema.isValid(data)) {
          content.chapters.push(data);
        }
      })
    ),
    Promise.all(
      pages[type][language].map(async item => {
        const data = item as TPages;
        if (await PagesAttributesSchema.isValid(data)) {
          content.pages.push(data);
        }
      })
    )
  ]);

  return { content, language, type };
};
