import alasql from 'alasql';
import { TQuestion } from './question.schema';
import { getFBCategoryName } from '../utils/utils';
import { CategoryType } from '../reducer/question.types';
import question from '../db/question.json';
import seniorquestion from '../db/seniorquestion.json';
import privatequestion from '../db/privatequestion.json';
import businessquestion from '../db/businessquestion.json';
import apptype from '../utils/apptype';

const content = {
  senior: seniorquestion,
  private: privatequestion,
  business: businessquestion
};

export const getQuestionsByTopic = async (topicId: number, currentCategory: CategoryType) => {
  const allQuestionList: TQuestion[] = alasql(
    'SELECT * FROM ? WHERE category = ? AND themeid = ? ORDER BY sortnr ASC',
    [content[apptype.type], getFBCategoryName(currentCategory), topicId]
  );

  return allQuestionList;
};

export const getDemoQuestions = async (currentCategory: CategoryType) => {
  const allQuestionList: TQuestion[] = alasql('SELECT * FROM ? WHERE category = ? ORDER BY sortnr ASC', [
    question,
    getFBCategoryName(currentCategory),
    true
  ]);

  return allQuestionList;
};

export const getAllQuestions = async (currentCategory: CategoryType) => {
  const allQuestionList: TQuestion[] = alasql('SELECT * FROM ? ORDER BY sortnr ASC', [
    content[apptype.type],
    getFBCategoryName(currentCategory)
  ]);

  return allQuestionList;
};

export const getQuestions = async (topic: number | string, currentCategory: CategoryType) => {
  switch (topic) {
    case 'demo':
      return getDemoQuestions(currentCategory);
    case 'all':
      return getAllQuestions(currentCategory);
    default:
      return getQuestionsByTopic(+topic, currentCategory);
  }
};
