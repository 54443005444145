import React from 'react';
import { IMarketProps } from './Market.types';
import styles from './Market.module.css';

const Market: React.FC<IMarketProps> = ({ src }: IMarketProps) => (
  <div className={styles.storeBtnItem}>
    <img src={src} alt={src} />
  </div>
);

export default Market;
