import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './VipCard.module.css';

const VipCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LinkWrapper className={styles.vipCard} to="/code">
      <div className="linkBtn">
        {t('homePage.btn.redeemCode')}
        <i className="icon-arrow" />
      </div>
    </LinkWrapper>
  );
};

export default VipCard;
