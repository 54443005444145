import React, { useState, useEffect, useCallback, useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';
import { Player, BigPlayButton } from 'video-react';
import { IContentBookProps } from './ContentBook.types';
import {
  getContentImage,
  getContentVideo,
  scrollToAnchor,
  splitTextByLines,
  splitTextContentWithImgToChunks
} from '../../utils/utils';
import ReadSpeaker from '../ReadSpeaker/ReadSpeaker';
import { THEORY_POSTFIX } from '../../constants/rsPostfix';
import { P3 } from '../../ui-components/P3/P3';
import { IPages } from '../../reducer/content.types';
import Image from '../../ui-components/Image/Image';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { THEORY_PAGE_ANCHOR } from '../../constants/anchors';
import styles from './ContentBook.module.css';

export const ContentBook: React.FC<IContentBookProps> = ({
  page,
  type,
  pageChapters,
  groupPages,
  currentLanguage
}: IContentBookProps) => {
  const isTopicsMode = false;
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const pages: string[] = Object.keys(groupPages);
  const { history, location }: RouteComponentProps = useReactRouter();
  const prevLocationKeyRef = useRef<string | undefined>(location.key);
  const currentPage: IPages[] = groupPages[pages[index]];
  const title = pageChapters[pages[index]];

  const ref = useRef<HTMLDivElement>(document.createElement('div'));

  const scrollReset = () => {
    scrollToAnchor(THEORY_PAGE_ANCHOR);
    ref.current.scrollTop = 0;
  };

  const handleChangePathname = useCallback(
    (selectedPage: string) => {
      history.push(`/${currentLanguage}/content/${type}/${selectedPage}`);
      scrollReset();
    },
    [history, currentLanguage, type]
  );

  const handleNextPage = useCallback(() => {
    if (pages[index + 1]) {
      handleChangePathname(pages[index + 1]);
    }
  }, [handleChangePathname, index, pages]);

  const handlePrevPage = useCallback(() => {
    if (pages[index - 1]) {
      handleChangePathname(pages[index - 1]);
    }
  }, [handleChangePathname, index, pages]);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        // left
        if (groupPages[pages[index - 1]]) {
          handlePrevPage();
        }
      }
      if (e.keyCode === 39) {
        // right
        if (groupPages[pages[index + 1]]) {
          handleNextPage();
        }
      }
    },
    [index, pages, groupPages, handlePrevPage, handleNextPage]
  );

  useEffect(() => {
    // attach the listeners on component mount
    document.addEventListener('keyup', escapeListener);
    return () => {
      // detach the listeners on component unmount
      document.removeEventListener('keyup', escapeListener);
    };
  }, [handleChangePathname, escapeListener]);

  useEffect(() => {
    setIndex(Object.keys(groupPages).indexOf(String(page)));
  }, [page, groupPages]);

  useEffect(() => {
    prevLocationKeyRef.current = location.key;
    scrollReset();
  }, [location]);

  const composeTheoryTitle = title ? <p className={`glossary-title text-to-read${THEORY_POSTFIX}`}>{title}</p> : null;

  const composedContent = currentPage
    ? currentPage.map((pageFromGroup: IPages, i) => {
        const [subTitle, ...texts]: string[] = splitTextByLines(pageFromGroup.text);
        const filteredParagraphs = texts.filter(Boolean);

        const videoSrc = pageFromGroup.vid ? getContentVideo(currentLanguage, type, pageFromGroup.vid) : '';
        return (
          // eslint-disable-next-line
          <div className={styles.glossaryImgList} key={`${page}${currentLanguage}${i}`}>
            <p className={cx('glossary-subtitle', `text-to-read${THEORY_POSTFIX}`)}>{subTitle}</p>
            {pageFromGroup.img && (
              <Image
                key={pageFromGroup.img}
                className={styles.imgContent}
                placeholderClassName={styles.imgPlaceholderContent}
                src={getContentImage(currentLanguage, type, pageFromGroup.img)}
              />
            )}
            {filteredParagraphs.map((textItem, textIndex) => {
              if (!textItem.includes('[IMAGE')) {
                return (
                  // eslint-disable-next-line
                  <P3 key={`${textItem}${textIndex}`} className={cx('glossary-text', `text-to-read${THEORY_POSTFIX}`)}>
                    {textItem}
                  </P3>
                );
              }

              return splitTextContentWithImgToChunks(textItem).map(subItem => {
                return (
                  <span key={subItem} className={`text-to-read${THEORY_POSTFIX}`}>
                    {subItem}
                  </span>
                );
                // const imgKey = filterImgList[Number(getTheoryContentImgAnchorIndex(subItem)) - 1];
                // return imgKey ? (
                //   <Image
                //     key={imgKey}
                //     className={styles.imgContent}
                //     placeholderClassName={styles.imgPlaceholderContent}
                //     src={getTheoryImage(imgKey)}
                //   />
                // ) : (
                //   <span key={subItem} className={`text-to-read${THEORY_POSTFIX}`}>
                //     {subItem}
                //   </span>
                // );
              });
            })}
            {pageFromGroup.link && (
              <a rel="noopener noreferrer" target="_blank" href={pageFromGroup.link}>
                {t('theoryPage.pageView.link')}
              </a>
            )}
            {pageFromGroup.vid && (
              <Player className={styles.movieSpacer} playsInline muted startTime={'0.1'} src={videoSrc}>
                <BigPlayButton position="center" />
              </Player>
            )}
          </div>
        );
      })
    : null;

  const controls = (
    <div className={styles.glossaryFooter}>
      <Button
        className={styles.btnPrev}
        variant="secondary"
        onClick={handlePrevPage}
        disabled={!groupPages[pages[index - 1]]}
      >
        <i className="icon-arrow" />
      </Button>
      {isTopicsMode ? (
        <LinkWrapper to="/theory" className={styles.topicLink}>
          {t('theoryPage.pageView.linkBack')}
        </LinkWrapper>
      ) : (
        <p className={styles.topicNumber}>{`${index + 1} / ${pages.length}`}</p>
      )}
      <Button
        className={styles.btnNext}
        variant="secondary"
        onClick={handleNextPage}
        disabled={!groupPages[pages[index + 1]]}
      >
        <i className="icon-arrow" />
      </Button>
    </div>
  );

  return (
    <>
      <div ref={ref} className={cx(styles.glossaryScrollBox, { [styles.withModal]: false })}>
        <div className={styles.glossaryHeader}>
          {' '}
          {composeTheoryTitle}
          <ReadSpeaker
            className={styles.questionBtnListItem}
            lng={currentLanguage}
            rsPostfix={THEORY_POSTFIX}
            stopParam={index}
          />
        </div>

        {composedContent}
        {controls}
      </div>
    </>
  );
};
