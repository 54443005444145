import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../reducer';
import { GET_CONTENT_REQUEST, CHANGE_CONTENT_MATCH_PARAMS } from '../actions/content.action';
import { ContentBook } from '../components/ContentBook/ContentBook';
import { IContentBookProps } from './ContentBookContainer.types';
import { Loader } from '../ui-components/Loader/Loader';
import { RequestStatusType } from '../reducer/fetch.types';
import { UNCALLED, PENDING } from '../constants/store.constants';
import { selectLanguage } from '../reducer/user.selectors';
import styles from './ContentBookContainer.module.css';

const ContentBookContainer: React.FC<IContentBookProps> = ({ page, type }: IContentBookProps) => {
  const dispatch = useDispatch();
  const pageStore = useSelector(({ content }: IRootState) => content.page);
  const pageChapters = useSelector(({ content }: IRootState) => content.content.pageChapters);
  const groupPages = useSelector(({ content }: IRootState) => content.content.groupPages);
  const language = useSelector(selectLanguage);
  const requestStatus: RequestStatusType = useSelector(({ content }: IRootState) => content.contentRequest.status);

  useEffect(() => {
    dispatch(GET_CONTENT_REQUEST({ language, type }));
  }, [dispatch, language, type]);

  useEffect(() => {
    dispatch(CHANGE_CONTENT_MATCH_PARAMS({ page }));
  }, [dispatch, page]);

  if (requestStatus === 'failure') {
    return <div>Error</div>;
  }

  return (
    <div className={styles.glossaryContent}>
      <Loader show={requestStatus === PENDING || requestStatus === UNCALLED} />
      <ContentBook
        groupPages={groupPages}
        page={pageStore}
        type={type}
        pageChapters={pageChapters}
        currentLanguage={language}
      />
    </div>
  );
};

export default ContentBookContainer;
