import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import Counter from '../../ui-components/Counter/Counter';
import QuestionView from '../../components/QuestionView/QuestionView';
import QuestionBar from '../../components/QuestionBar/QuestionBar';
import { IRootState } from '../../reducer';
import { IQuestion } from '../../reducer/question.types';
import { RequestStatusType } from '../../reducer/fetch.types';
import { TopicSelectorContainer } from '../TopicSelectorContainer';
import { IQuestionCardContainerProps } from './QuestionCardContainer.types';
import {
  ADD_ANSWER_REQUEST,
  CHANGE_ANSWER,
  GET_ANSWER_FROM_FIREBASE_REQUEST,
  CLEAR_CURRENT_ANSWER_REQUEST
} from '../../actions/answer.action';
import {
  SET_LANGUAGE_TOGGLE_STATE,
  SET_SELECTED_QUESTION_LANGUAGE,
  SET_USER_QUESTION_LANGUAGE_SUCCESS,
  SET_USER_SELECTED_CATEGORY,
  SET_USER_SELECTED_QUESTION
} from '../../actions/user.action';
import { GET_QUESTION_REQUEST } from '../../actions/question.action';
import { IUserAnswer } from '../../reducer/user.types';
import styles from './QuestionCardContainer.module.css';
import { Loader } from '../../ui-components/Loader/Loader';
import { selectLanguage } from '../../reducer/user.selectors';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import PlaceholderBox from '../../components/PlaceholderBox/PlaceholderBox';
import { FAILURE, PENDING, UNCALLED } from '../../constants/store.constants';
import { usePrevious } from '../../hooks/usePrevious';
import Ads from '../../components/Ads/Ads';
import { P3 } from '../../ui-components/P3/P3';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';

export const QuestionCardContainer: React.FC<IQuestionCardContainerProps> = ({
  routeParams
}: IQuestionCardContainerProps) => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const questionList: IQuestion[] = useSelector(({ question }: IRootState) => question.questionList);
  const requestStatus: RequestStatusType = useSelector(
    ({ question }: IRootState) => question.questionListRequest.status
  );
  const questionLanguage: string = useSelector(({ user: { profile } }: IRootState) => profile.questionLanguage);
  const { topic, id = 1, category } = routeParams;
  const isDemo = topic === 'demo';
  const selectedQuestionId: number = id && +id > 0 && +id <= questionList.length ? +id - 1 : 0;
  const question: IQuestion = questionList[selectedQuestionId];
  const userAnswer: IUserAnswer | null = useSelector(({ answer: { answerList } }: IRootState) => {
    return question && answerList && answerList[question.themeid] ? answerList[question.themeid][question.id] : null;
  });
  const isCorrect: boolean | undefined = userAnswer ? userAnswer.correct : undefined;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { history } = useReactRouter();
  const userLanguage = useSelector(selectLanguage);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const prevLanguage = usePrevious(userLanguage);
  const selectedQuestionLang: string | undefined = useSelector(
    ({ user: { profile } }: IRootState) => profile.selectedQuestionLang
  );

  const toggleLngState: boolean | undefined = useSelector(
    ({ user: { profile } }: IRootState) => profile.toggleLngState
  );

  const changeLanguage = useCallback(
    (language: string): void => {
      dispatch(SET_USER_QUESTION_LANGUAGE_SUCCESS({ language }));
    },
    [dispatch]
  );

  const setToggleState = useCallback(toggleState => dispatch(SET_LANGUAGE_TOGGLE_STATE({ toggleState })), [dispatch]);

  const handleToggleChangeLng = useCallback(
    (newLng: string) => {
      setToggleState(false);
      dispatch(SET_SELECTED_QUESTION_LANGUAGE(newLng));
      changeLanguage(newLng);
    },
    [dispatch, changeLanguage, setToggleState]
  );

  const handleLngToggleClick = useCallback(() => {
    changeLanguage(!toggleLngState ? userLanguage : selectedQuestionLang);
    setToggleState(!toggleLngState);
  }, [toggleLngState, setToggleState, changeLanguage, selectedQuestionLang, userLanguage]);

  const handleQuestionCheck = useCallback(() => {
    if (userAnswer) {
      let correctStatus: boolean = !question.correct.some(
        (item: number) => !(userAnswer.selected.indexOf(item - 1) >= 0)
      );

      correctStatus = correctStatus ? userAnswer.selected.length === question.correct.length : correctStatus;

      dispatch(
        ADD_ANSWER_REQUEST({
          questionId: question.id,
          qTopic: question.themeid,
          answer: {
            correct: correctStatus,
            selected: [...userAnswer.selected]
          }
        })
      );
    }
  }, [userAnswer, dispatch, question]);

  const handleAnswerChange = useCallback(
    (checked: boolean, answerId: number) => {
      dispatch(CHANGE_ANSWER({ answerId, question, userAnswer }));
    },
    [dispatch, userAnswer, question]
  );

  const handleQuestionChange = useCallback(
    (link: string) => {
      history.push(link);
    },
    [history]
  );

  const handleCurrentQuestionDelete = useCallback(() => {
    dispatch(CLEAR_CURRENT_ANSWER_REQUEST({ question }));
  }, [question, dispatch]);

  useEffect(() => {
    if (!isLogged && !isDemo) {
      history.push(`/${userLanguage}/`);
    }
  }, [isLogged, isDemo, history, userLanguage]);

  useEffect(() => {
    if (isLogged && isDemo) {
      history.push(`/${userLanguage}/questions`);
    }
  }, [isLogged, isDemo, history, userLanguage]);

  useEffect(() => {
    dispatch(SET_USER_SELECTED_QUESTION(selectedQuestionId));
  }, [dispatch, selectedQuestionId]);

  useEffect(() => {
    if (category !== currentCategory) {
      dispatch(SET_USER_SELECTED_CATEGORY({ category }));
    }
  }, [dispatch, category, currentCategory]);

  useEffect(() => {
    dispatch(GET_QUESTION_REQUEST({ topic }));
    dispatch(GET_ANSWER_FROM_FIREBASE_REQUEST());
  }, [dispatch, topic]);

  useEffect(() => {
    if (typeof toggleLngState !== 'boolean' && prevLanguage && prevLanguage !== userLanguage) {
      setToggleState(!!toggleLngState);
    }
  }, [toggleLngState, dispatch, prevLanguage, userLanguage, setToggleState]);

  const isUserAnswered = userAnswer ? userAnswer.correct !== undefined : false;

  return (
    <div className={styles.questionPage}>
      <Loader show={[PENDING, UNCALLED].includes(requestStatus)} />
      <PlaceholderBox show={[UNCALLED, PENDING, FAILURE].includes(requestStatus)} height="400px">
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.mobileTopic}>{!isDemo && <TopicSelectorContainer selectedTopicId={topic} />}</div>
              <div className={styles.questionBar}>
                <div className={styles.headerQuestionBox}>
                  {question && (
                    <div className={styles.doubleCounter}>
                      <Counter current={selectedQuestionId + 1} maxSize={questionList.length} />
                    </div>
                  )}
                  {!isDemo && <TopicSelectorContainer selectedTopicId={topic} />}
                </div>
                <div>
                  {question && (
                    <QuestionBar
                      isLogged={isLogged}
                      languageToggleState={toggleLngState}
                      selectedLang={toggleLngState && selectedQuestionLang ? selectedQuestionLang : userLanguage}
                      onLngToggleClick={handleLngToggleClick}
                      onToggleChangeLng={handleToggleChangeLng}
                      questionId={selectedQuestionId}
                      onQuestionChange={handleQuestionChange}
                      topic={topic}
                      theoryId={question.theoryid}
                      userLanguage={userLanguage}
                      defaultRoute={`/${userLanguage}/questions/${currentCategory}/${topic}`}
                      questionList={questionList}
                      onLngChange={changeLanguage}
                      lng={questionLanguage}
                      questionLngList={Object.keys(question.langs)}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {question && (
            <QuestionView
              onQuestionChange={handleQuestionChange}
              onCheckedAnswerChange={handleAnswerChange}
              selectedAnswers={userAnswer ? userAnswer.selected : []}
              isCorrect={isCorrect}
              lng={questionLanguage}
              question={question}
              questionId={selectedQuestionId + 1}
              defaultRoute={`/${userLanguage}/questions/${currentCategory}/${topic}`}
              questionCount={questionList.length}
              onQuestionCheck={handleQuestionCheck}
            />
          )}
          <div className={styles.questionLinkBottom}>
            <Button disabled={!isUserAnswered} variant="secondary" onClick={handleCurrentQuestionDelete}>
              {t('questionCard.resetAnswer')}
            </Button>
            <LinkWrapper className="btnLabel btn" to="/questions">
              {t('questionCard.exitLearningMode')}
            </LinkWrapper>
          </div>
          {!isLogged && (
            <Jumbotron className={styles.theoryMobile}>
              <PageTitle>{t('questionCard.mobileTitle')}</PageTitle>
              <P3>{t('questionCard.mobileText')}</P3>
              <div className={styles.theoryMobileFooter}>
                <LinkWrapper className="linkBtn" to="/theory">
                  {t('questionCard.mobileBtn')}
                  <i className="icon-arrow" />
                </LinkWrapper>
              </div>
            </Jumbotron>
          )}
        </Container>
      </PlaceholderBox>
      <Ads />
    </div>
  );
};
