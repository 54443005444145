import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './Footer.module.css';

const footerLogo = imageAbsolutePath('footer-logo.svg');
const brandMain = imageAbsolutePath('brand-main.png');

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={3}>
            <LinkWrapper className={styles.footerLogo} to="/">
              <img className="responsive-img" src={footerLogo} alt="footer-logo" />
            </LinkWrapper>

            <ul className={styles.socialLinkList}>
              <li>
                <a
                  className={styles.socialLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ctmalder/"
                >
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/theorie24.ch/"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t('info.emailhref')}
                >
                  <i className="icon-email" />
                </a>
              </li>
              <li>
                <a className={styles.socialLinkItem} rel="noopener noreferrer" href={t('info.telhref')}>
                  <i className="icon-whatsapp" />
                </a>
              </li>
              <li>
                <LinkWrapper to="/contacts" className={styles.socialLinkItem}>
                  <i className="icon-support" />
                </LinkWrapper>
              </li>
            </ul>
          </Col>
          <Col lg={5} md={6} sm={6}>
            <div className={styles.footerTextWrapper}>
              <div className={styles.footerText}>
                <span className={styles.footerTextItem}>{t('footer.CTMAlderAG')}</span>
                <span className={styles.footerTextItem}>{t('footer.GlutzBlotzheimStrasse3')}</span>
                <span className={styles.footerTextItem}>{t('footer.CH4500Solothurn')}</span>
              </div>
              <div className={styles.footerText}>
                {t('info.tellabel')}
                {' · '}
                {t('footer.web')}
              </div>
              <div className={styles.footerCopyright}>
                {`© ${new Date().getFullYear()} `}
                copyright by Reist Telecom AG
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                  {t('footer.TOC')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                  {t('footer.imprint')}
                </LinkWrapper>
                {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.brandLinkList}>
              <p>{t('footer.moreProducts')}</p>
              <a
                className={styles.brandLinkItem}
                rel="noopener noreferrer"
                target="_blank"
                href={t('footer.fahrschultheorieLink')}
              >
                <img src={brandMain} width={160} height="auto" alt="fahrschultheorie" />
              </a>
            </div>
          </Col>
          <Col sm={12}>
            <div className={styles.footerCopyrightMobile}>
              {`© ${new Date().getFullYear()} `}
              copyright by Reist Telecom AG
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                {t('footer.TOC')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                {t('footer.imprint')}
              </LinkWrapper>
              {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
