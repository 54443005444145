import React, { useCallback, useState } from 'react';
import styles from './ZoomImage.module.css';
import MultimediaModal from '../../components/MultimediaModal/MultimediaModal';
import Image from '../Image/Image';
import ZoomBtn from '../ZoomBtn/ZoomBtn';
import { IZoomImageProps } from './ZoomImage.types';

const ZoomImage: React.FC<IZoomImageProps> = ({ src, description }: IZoomImageProps) => {
  const [showImgWin, setShowImgWin] = useState(false);
  const handleHide = useCallback(() => {
    setShowImgWin(false);
  }, [setShowImgWin]);

  const handleShow = useCallback(() => {
    setShowImgWin(true);
  }, [setShowImgWin]);

  return (
    <div className={styles.root}>
      <Image src={src} width="100%" alt={description} />
      <ZoomBtn className={styles.zoomBtn} onClick={handleShow} />
      <MultimediaModal src={src} alt={description} show={showImgWin} onHide={handleHide} className={styles.modal} />
    </div>
  );
};

export default ZoomImage;
