import { isSSR } from './utils';

const parts = isSSR() ? [] : window.location.host.split('.');

const curtype = parts.length > 1 ? parts[0] : 'senior';

const apptype = {
  type: curtype,
  path: (filename: string) => {
    return `/static/${curtype}/${filename}`;
  }
};

export default apptype;
