import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { TopicState } from './topic.types';
import { IUserState } from './user.types';
import { LoginState } from './login.types';
import { SchoolState } from './school.types';
import { IQuestionState } from './question.types';
import { IOffersState } from './offer.types';
import { IAnswerState } from './answer.types';
import { IPaymentMethodState } from './paymentMethod.types';
import { IRegisterState } from './register.types';
import { IExamState } from './exam.types';
import { ITheoryState } from './theory.types';
import { IContentState } from './content.types';
import topic from './topic';
import question from './question';
import category from './category';
import login from './login';
import user from './user';
import school from './school';
import answer from './answer';
import exam from './exam';
import offer from './offer';
import paymentMethod from './paymentMethod';
import register from './register';
import theory from './theory';
import content from './content';
import auth from './auth';
import voucher from './voucher';
import { IAuthState } from './auth.types';
import { IVoucherState } from './voucher.types';
import { ICategoryState } from './category.types';

export interface IRootState {
  readonly auth: IAuthState;
  readonly topic: TopicState;
  readonly user: IUserState;
  readonly category: ICategoryState;
  readonly answer: IAnswerState;
  readonly question: IQuestionState;
  readonly login: LoginState;
  readonly exam: IExamState;
  readonly school: SchoolState;
  readonly offer: IOffersState;
  readonly router: RouterState;
  readonly paymentMethod: IPaymentMethodState;
  readonly register: IRegisterState;
  readonly voucher: IVoucherState;
  readonly theory: ITheoryState;
  readonly content: IContentState;
}

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    question,
    auth,
    category,
    topic,
    answer,
    login,
    user,
    exam,
    school,
    offer,
    paymentMethod,
    register,
    voucher,
    theory,
    content
  });

export default rootReducer;
