import React from 'react';
import { IPromoIconProps } from './PromoIcon.types';
import { imageAbsolutePath } from '../../utils/utils';
import styles from './PromoIcon.module.css';
import apptype from '../../utils/apptype';

const vWissenDe = imageAbsolutePath('front/verkehr_auffrisch_de.svg');
const vWissenFr = imageAbsolutePath('front/verkehr_auffrisch_fr.svg');
const vWissenIt = imageAbsolutePath('front/verkehr_auffrisch_it.svg');
const vWissenEn = imageAbsolutePath('front/wissen_en.svg');
const vUnabhDe = imageAbsolutePath('front/unabhaengig_mobil_de.svg');
const vUnabhFr = imageAbsolutePath('front/unabhaengig_mobil_fr.svg');
const vUnabhIt = imageAbsolutePath('front/unabhaengig_mobil_it.svg');
const vUnabhEn = imageAbsolutePath('front/sicherheit_en.svg');
const vSicherheitDe = imageAbsolutePath('front/sicher_unterwegs_de.svg');
const vSicherheitFr = imageAbsolutePath('front/sicher_unterwegs_fr.svg');
const vSicherheitIt = imageAbsolutePath('front/sicher_unterwegs_it.svg');
const vSicherheitEn = imageAbsolutePath('front/unabhaengig_en.svg');
const vSicherheiterhDe = imageAbsolutePath('front/sicherheit_erhoehen_de.svg');
const vSicherheiterhFr = imageAbsolutePath('front/sicherheit_erhoehen_fr.svg');
const vSicherheiterhIt = imageAbsolutePath('front/sicherheit_erhoehen_it.svg');
// import vSicherheiterhEn from '../../img/front/unabhaengig_en.svg';
const vJunglenkerDe = imageAbsolutePath('front/junglenker_begl_de.svg');
const vJunglenkerFr = imageAbsolutePath('front/junglenker_begl_fr.svg');
const vJunglenkerIt = imageAbsolutePath('front/junglenker_begl_it.svg');
// import vJunglenkerEn from '../../img/front/unabhaengig_en.svg';
const vInfoDe = imageAbsolutePath('front/info_bleiben_de.svg');
const vInfoFr = imageAbsolutePath('front/info_bleiben_fr.svg');
const vInfoIt = imageAbsolutePath('front/info_bleiben_it.svg');
const vInfoEn = imageAbsolutePath('front/info_en.svg');

const SRCS = {
  de: {
    '1': {
      senior: vWissenDe,
      private: vWissenDe,
      business: vWissenDe
    },
    '2': {
      senior: vUnabhDe,
      private: vInfoDe,
      business: vInfoDe
    },
    '3': {
      senior: vSicherheitDe,
      private: vJunglenkerDe,
      business: vSicherheiterhDe
    }
  },
  fr: {
    '1': {
      senior: vWissenFr,
      private: vWissenFr,
      business: vWissenFr
    },
    '2': {
      senior: vUnabhFr,
      private: vInfoFr,
      business: vInfoFr
    },
    '3': {
      senior: vSicherheitFr,
      private: vJunglenkerFr,
      business: vSicherheiterhFr
    }
  },
  it: {
    '1': {
      senior: vWissenIt,
      private: vWissenIt,
      business: vWissenIt
    },
    '2': {
      senior: vUnabhIt,
      private: vInfoIt,
      business: vInfoIt
    },
    '3': {
      senior: vSicherheitIt,
      private: vJunglenkerIt,
      business: vSicherheiterhIt
    }
  },
  en: {
    '1': {
      senior: vWissenEn,
      private: vWissenEn,
      business: vWissenEn
    },
    '2': {
      senior: vUnabhEn,
      private: vInfoEn,
      business: vInfoEn
    },
    '3': {
      senior: vSicherheitEn,
      private: vSicherheitEn,
      business: vSicherheitEn
    }
  }
};

const Icon: React.FC<IPromoIconProps> = ({ src, alt, currentLanguage }: IPromoIconProps) => {
  const srcPath = SRCS[currentLanguage][src][apptype.type] || SRCS.de;
  return (
    <div className={styles.iconsListItem}>
      <img src={srcPath} alt={alt} />
    </div>
  );
};

export default Icon;
