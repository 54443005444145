import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomeCarouselMobile.module.css';

const HomeCarouselMobile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mobileSlider}>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('homePage.slides.fromCHF.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.fromCHF.text')}</p>
      </div>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('homePage.slides.everythingLogin.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.everythingLogin.text')}</p>
      </div>
      <div className={styles.mobileSliderItem}>
        <div className={styles.sliderTitle}>{t('homePage.slides.lngRules.title')}</div>
        <p className={styles.sliderText}>{t('homePage.slides.lngRules.textLng')}</p>
      </div>
    </div>
  );
};

export default HomeCarouselMobile;
