import React, { VideoHTMLAttributes } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ITeacher3dCarouselProps } from './Teacher3dCarousel.types';
import poster from '../../img/poster.svg';
import styles from './Teacher3dCarousel.module.css';
import { P3 } from '../../ui-components/P3/P3';

const SLIDE_INTERVAL = 30 * 1000;

const DEFAULT_VIDEO_PROPS: VideoHTMLAttributes<HTMLVideoElement> = {
  autoPlay: false,
  controls: true,
  muted: true,
  loop: true,
  preload: 'auto'
};

const Teacher3dCarousel: React.FC<ITeacher3dCarouselProps> = ({
  srcArray,
  videoProps = {}
}: ITeacher3dCarouselProps) => {
  const { t } = useTranslation();

  return (
    <Carousel interval={SLIDE_INTERVAL}>
      {srcArray.map(item => {
        return (
          <Carousel.Item key={item.src}>
            <P3>{t(`${item.text}`)}</P3>
            <div className={styles.videoContainer}>
              <video
                className={styles.responsiveVideo}
                {...DEFAULT_VIDEO_PROPS}
                {...videoProps}
                muted
                poster={isMobileSafari ? poster : undefined}
              >
                <source src={item.src} type="video/mp4" />
              </video>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default React.memo(Teacher3dCarousel);
