import { handleActions } from 'redux-actions';
import { IContentState } from './content.types';
import {
  GET_CONTENT_REQUEST,
  GET_CONTENT_FAILURE,
  GET_CONTENT_SUCCESS,
  CHANGE_CONTENT_MATCH_PARAMS
} from '../actions/content.action';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { groupBy } from '../utils/groupBy';

const defaultState: IContentState = {
  content: {
    chapters: [],
    pages: [],
    pageChapters: {},
    groupPages: {}
  },
  contentRequest: {
    status: 'uncalled',
    error: null
  },
  type: '',
  page: 1,
  language: 'de'
};

const groupByPages = groupBy('page');

const reduces = handleActions(
  {
    [GET_CONTENT_REQUEST]: state => ({
      ...state,
      contentRequest: { status: PENDING, error: null }
    }),
    [GET_CONTENT_FAILURE]: action => ({
      ...defaultState,
      contentRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_CONTENT_SUCCESS]: (state, action) => ({
      ...state,
      contentRequest: { status: SUCCESS, error: null },
      content: {
        ...action.payload.content,
        pageChapters: action.payload.content.chapters.reduce((acc, chapter) => {
          acc[chapter.page] = chapter.title;
          return acc;
        }, {}),
        groupPages: groupByPages(action.payload.content.pages)
      },
      type: action.payload.type,
      language: action.payload.language
    }),
    [CHANGE_CONTENT_MATCH_PARAMS]: (state, action) => ({
      ...state,
      page: +action.payload.page
    })
  },
  defaultState
);

export default reduces;
