import { put, takeLatest, select, call, all } from 'redux-saga/effects';
import { GET_QUESTION_FAILURE, GET_QUESTION_SUCCESS, GET_QUESTION_REQUEST } from '../actions/question.action';
import { getQuestions } from './question.api';
import { IRootState } from '../reducer';
import { CategoryType, IQuestion } from '../reducer/question.types';
import { prefetchQuestionsMediaSources } from '../utils/prefetchMediaSources';

const getCategory = ({ user }: IRootState) => user.profile.currentCategory;

function* getQuestionList(action) {
  try {
    const currentCategory: CategoryType = yield select(getCategory);
    const data: IQuestion[] = yield call(getQuestions, action.payload.topic, currentCategory);
    yield put(GET_QUESTION_SUCCESS(data));
  } catch (e) {
    yield put(GET_QUESTION_FAILURE(e.message));
  }
}

function prefetchQuestionMedia({ payload }: { payload: IQuestion[] }) {
  prefetchQuestionsMediaSources(payload);
}

function* questionSaga() {
  yield all([
    takeLatest(GET_QUESTION_REQUEST, getQuestionList),
    takeLatest(GET_QUESTION_SUCCESS, prefetchQuestionMedia)
  ]);
}

export default questionSaga;
