import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import styles from './HomeCards.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const HomeCards: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <div className={styles.promoCardList}>
          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.newExam.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.newExam.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <LinkWrapper className="linkBtn" to="/about">
                {t('homePage.cards.newExam.link')}
                <i className="icon-arrow" />
              </LinkWrapper>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.vouchers.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.vouchers.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <LinkWrapper className="linkBtn" to="/about">
                {t('homePage.cards.schoolCoupon.link')}
                <i className="icon-arrow" />
              </LinkWrapper>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.schoolCoupon.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.schoolCoupon.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <LinkWrapper className="linkBtn" to="/about">
                {t('homePage.cards.schoolCoupon.link')}
                <i className="icon-arrow" />
              </LinkWrapper>
            </div>
          </div>

          <div className={styles.promoCardItem}>
            <div className={styles.cardContent}>
              <h6 className={styles.cardContentTitle}>{t('homePage.cards.scooter.title')}</h6>
              <p className={styles.cardContentText}>{t('homePage.cards.scooter.text')}</p>
            </div>
            <div className={styles.cardFooter}>
              <LinkWrapper className="linkBtn" to="/about">
                {t('homePage.cards.scooter.link')}
                <i className="icon-arrow" />
              </LinkWrapper>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HomeCards;
