import { handleActions } from 'redux-actions';
import {
  GET_ANSWER_FROM_FIREBASE_REQUEST,
  GET_ANSWER_FROM_FIREBASE_FAILURE,
  GET_ANSWER_FROM_FIREBASE_SUCCESS,
  ADD_ANSWER_REQUEST,
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAILURE,
  CLEAR_ANSWER_BY_TOPIC_SUCCESS,
  CLEAR_ANSWER_BY_TOPIC_REQUEST,
  CLEAR_ANSWER_BY_TOPIC_FAILURE,
  CLEAR_ANSWER,
  CLEAR_CURRENT_ANSWER_SUCCESS,
  CLEAR_CURRENT_ANSWER_REQUEST,
  CLEAR_CURRENT_ANSWER_FAILURE
} from '../actions/answer.action';
import { defaultRequestStatus } from './fetch.types';
import { FAILURE, PENDING, SUCCESS } from '../constants/store.constants';
import { IAnswerState } from './answer.types';

const defaultState: IAnswerState = {
  answerList: {},
  answerRequest: defaultRequestStatus,
  clearUserAnswer: defaultRequestStatus,
  clearCurrentUserAnswerRequest: defaultRequestStatus,
  addAnswerRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [ADD_ANSWER_REQUEST]: (state, action) => {
      const answerList = {
        ...state.answerList,
        [action.payload.qTopic]: state.answerList[action.payload.qTopic]
          ? {
              ...state.answerList[action.payload.qTopic],
              [action.payload.questionId]: action.payload.answer
            }
          : {
              [action.payload.questionId]: action.payload.answer
            }
      };
      return {
        ...state,
        addAnswerRequest: PENDING,
        answerList
      };
    },
    [ADD_ANSWER_SUCCESS]: state => ({
      ...state,
      addAnswerRequest: SUCCESS
    }),
    [ADD_ANSWER_FAILURE]: state => ({
      ...state,
      addAnswerRequest: FAILURE
    }),
    [GET_ANSWER_FROM_FIREBASE_SUCCESS]: (state, actions) => ({
      ...state,
      answerRequest: { status: SUCCESS, error: null },
      answerList: actions.payload.answers
    }),
    [GET_ANSWER_FROM_FIREBASE_FAILURE]: (state, actions) => ({
      ...state,
      answerRequest: { status: FAILURE, error: actions.payload },
      answerList: {}
    }),
    [GET_ANSWER_FROM_FIREBASE_REQUEST]: state => ({
      ...state,
      answerRequest: { status: PENDING, error: null },
      answerList: {}
    }),
    [CLEAR_ANSWER_BY_TOPIC_REQUEST]: (state, action) => {
      let answerList = {};

      if (action.payload.topic !== 'all' && action.payload.topic !== 'demo') {
        answerList = {
          ...state.answerList,
          [action.payload.topic]: {}
        };
      }

      return {
        ...state,
        clearUserAnswer: { status: PENDING, error: null },
        answerList
      };
    },
    [CLEAR_ANSWER_BY_TOPIC_SUCCESS]: state => ({
      ...state,
      clearUserAnswer: { status: SUCCESS, error: null }
    }),
    [CLEAR_ANSWER_BY_TOPIC_FAILURE]: state => ({
      ...state,
      clearUserAnswer: { status: FAILURE, error: null }
    }),
    [CLEAR_CURRENT_ANSWER_REQUEST]: state => ({
      ...state,
      clearCurrentUserAnswerRequest: { status: PENDING, error: null }
    }),
    [CLEAR_CURRENT_ANSWER_SUCCESS]: (state, { payload }) => {
      const removeAnswerFormThemId = { ...state.answerList[payload.themeId] };
      delete removeAnswerFormThemId[payload.questionId];

      return {
        ...state,
        answerList: {
          ...state.answerList,
          [payload.themeId]: removeAnswerFormThemId
        },
        clearCurrentUserAnswerRequest: { status: SUCCESS, error: null }
      };
    },
    [CLEAR_CURRENT_ANSWER_FAILURE]: state => ({
      ...state,
      clearCurrentUserAnswerRequest: { status: FAILURE, error: null }
    }),
    [CLEAR_ANSWER]: () => ({
      ...defaultState
    })
  },
  defaultState
);

export default reduces;
