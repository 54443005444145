import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import OfferCardContainer from '../../containers/OfferCardContainer';
import VipCard from '../../components/VipCard/VipCard';
import { PageText } from '../../ui-components/PageText/PageText';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { selectLogin } from '../../reducer/login.selectors';
import { LoginState } from '../../reducer/login.types';
import { selectLanguage } from '../../reducer/user.selectors';
import styles from './OffersPage.module.css';
import { P3 } from '../../ui-components/P3/P3';
import PromoIcon from '../../components/PromoIcon/PromoIcon';
import apptype from '../../utils/apptype';

const OffersPage: React.FC = () => {
  const { t } = useTranslation();
  const { history, location } = useReactRouter();
  const { isLogged }: LoginState = useSelector(selectLogin);
  const currentLanguage = useSelector(selectLanguage);

  if (isLogged) {
    history.replace(location.pathname.replace('/offers', '/extend-offers'));
    return null;
  }

  return (
    <>
      <div className={styles.offersPageText}>
        <Container>
          <Row>
            <Col lg={6}>
              <PageTitle>{t(`offersPage.${apptype.type}`)}</PageTitle>
              <div className={styles.pageTextList}>
                <P3>{t('offersPage.subtitle1')}</P3>
                <P3>{t('offersPage.subtitle2')}</P3>
              </div>
            </Col>
            <Col lg={6}>
              <div className={styles.iconsList}>
                <PromoIcon src="1" currentLanguage={currentLanguage} alt="badge" />
                <PromoIcon src="2" currentLanguage={currentLanguage} alt="badge" />
                <PromoIcon src="3" currentLanguage={currentLanguage} alt="badge" />
              </div>
              <div className={styles.pageTextListMobile}>
                <PageText>{t('offersPage.subtitle1')}</PageText>
                <PageText>{t('offersPage.subtitle2')}</PageText>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.offerPageCard}>
        <OfferCardContainer />
      </div>
      <div className={styles.offerPageVip}>
        <Container>
          <Row>
            <Col>
              <div className={styles.offerPageContent}>
                <div className={styles.offerPageItem}>
                  <h3 className="pageSubtitle">{t('offersPage.vipcodeTitle')}</h3>
                  <VipCard />
                </div>
                <Card className={styles.offerCard}>
                  <Card.Body>
                    <Card.Title>{t('offersPage.giveAccess.title')}</Card.Title>
                    <Card.Text>{t('offersPage.giveAccess.subtitle')}</Card.Text>
                  </Card.Body>
                  <Card.Footer className={styles.cardFooterSingle}>
                    <Card.Link
                      className={styles.offerFooterLink}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={t(`offersPage.giveAccess.link.${apptype.type}`)}
                    >
                      {t('offersPage.giveAccess.button')}
                    </Card.Link>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OffersPage;
