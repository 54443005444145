import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import useReactRouter from 'use-react-router';
import { createMemoryHistory } from 'history';
import appHistory from './history';
import Header from '../components/Header/Header';
import { PrivateRoute } from './PrivateRoute';
import { routes } from './routes';
import { FAILURE, PENDING, SUCCESS, UNCALLED } from '../constants/store.constants';
import { SET_OFFLINE_MODE_STATE } from '../actions/auth.action';
import { IRootState } from '../reducer';
import { db, firebaseApp } from '../config/firebase.config';
import { LOGOUT_REQUEST } from '../actions/login.action';
import ModalAccessibilityContainer from '../containers/ModalAccessibilityContainer';
import ModalPlanExpiredContainer from '../containers/ModalPlanExpiredContainer/ModalPlanExpiredContainer';
import SignUpFinishContainer from '../containers/SignUpFinishContainer/SignUpFinishContainer';
import { DefaultRoute } from './DefaultRoute';
import Footer from '../components/Footer/Footer';
import { CLEAR_EXAM, GET_LAST_EXAM_REQUEST } from '../actions/exam.action';
import { selectLanguage } from '../reducer/user.selectors';
import LoginModalContainer from '../containers/LoginModalContainer';
import RecoveryPasswordModalContainer from '../containers/RecoveryPasswordModalContainer/RecoveryPasswordModalContainer';
import LogoutModalContainer from '../containers/LogoutModalContainer';
import GoodByeModalContainer from '../containers/GoodByeModalContainer';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';
import { UserLoginSuccessContainer } from '../containers/UserLoginSuccessContainer';
import { LoginContainer } from '../containers/LoginContainer/LoginContainer';
import { isSSR } from '../utils/utils';

interface IAppRouter {
  isOnline: boolean;
  SSRRout?: string;
}

export const AppRouter: React.FC<IAppRouter> = ({ isOnline, SSRRout }) => {
  const deviceUid: string = useSelector(({ auth }: IRootState) => auth.deviceUid);
  const updateDeviceUidStatus: string = useSelector(({ auth }: IRootState) => auth.updateDeviceUid.status);
  const { isLogged, isGoodByeModalOpen }: LoginState = useSelector(selectLogin);
  const firebaseAuth: boolean = useSelector(({ auth }: IRootState) => auth.firebaseAuth);
  const firebaseAuthStatus: string = useSelector(({ auth }: IRootState) => auth.firebaseAuthRequest.status);
  const firebaseAuthLoaded: boolean = firebaseAuthStatus === SUCCESS || firebaseAuthStatus === FAILURE;
  const lastExamRequestStatus = useSelector(({ exam }: IRootState) => exam.lastExamRequest.status);
  const lastExam = useSelector(({ exam }: IRootState) => exam.lastExam);
  const dispatch = useDispatch();
  const { history, location } = useReactRouter();
  const currentLanguage = useSelector(selectLanguage);
  const loaderState = false;

  useEffect(() => {
    dispatch(SET_OFFLINE_MODE_STATE(!isOnline));
  }, [dispatch, isOnline]);

  useEffect(() => {
    const auth = firebaseApp.auth();
    const currentUserId = auth && auth.currentUser ? auth.currentUser.uid : null;
    let dropListener = () => {};

    if (firebaseAuth && currentUserId && deviceUid && updateDeviceUidStatus === SUCCESS) {
      dropListener = db
        .collection('user')
        .doc(currentUserId)
        .onSnapshot(snapshot => {
          const data = snapshot.data() || {};
          const userDeviceUid = data.deviceUid;

          if (deviceUid !== userDeviceUid) {
            dispatch(LOGOUT_REQUEST());
          }
        });
    }
    return dropListener;
  }, [dispatch, isLogged, firebaseAuth, deviceUid, updateDeviceUidStatus]);

  useEffect(() => {
    if (lastExamRequestStatus === UNCALLED && !loaderState && firebaseAuth && firebaseAuthLoaded) {
      dispatch(GET_LAST_EXAM_REQUEST());
    }
  }, [dispatch, lastExamRequestStatus, loaderState, isLogged, firebaseAuth, firebaseAuthLoaded]);

  useEffect(() => {
    if (lastExam && !lastExam.finish && !lastExam.endTime && !loaderState) {
      const notFinished = lastExam.examStartTime + lastExam.duration * 60 * 1000 > Date.now();
      const newPath = `/${currentLanguage}/exam/${lastExam.uid}`;
      if (notFinished && isOnline && !history.location.pathname.includes(`/exam/${lastExam.uid}`)) {
        dispatch(CLEAR_EXAM());
        history.push(newPath);
      }
    }
  }, [lastExam, history, currentLanguage, isOnline, loaderState, dispatch]);

  useEffect(() => {
    const homePath = `/${currentLanguage}`;
    if (isGoodByeModalOpen && location.pathname !== homePath) {
      history.push(homePath);
    }
    // eslint-disable-next-line
  }, [history, isGoodByeModalOpen, location]);

  const headerRouteProps: RouteComponentProps = useReactRouter();

  const renderRouter = (
    <>
      <Header isLogged={isLogged} routeProps={headerRouteProps} />
      <main>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={`/:locale(en|de|it|fr)?${route.path}`}
              render={routeProps => (
                <PrivateRoute
                  routeProps={routeProps}
                  demoPathName={route.demoPathName}
                  renderAfterLoad={route.renderAfterLoad}
                  withLoader={route.withLoader}
                  component={route.component}
                  onlyForDemo={route.onlyForDemo}
                  hideExpired={route.hideExpired}
                  loaderState={loaderState && (lastExamRequestStatus !== PENDING || lastExamRequestStatus !== UNCALLED)}
                  route={route}
                />
              )}
            />
          ))}
          <Route path="*" component={DefaultRoute} />
        </Switch>
      </main>
      <Footer />
      <ModalPlanExpiredContainer />
      <ModalAccessibilityContainer isOnline={isOnline} />
      <Route path="/:locale(en|de|it|fr)?/:path*" component={UserLoginSuccessContainer} />
      <LoginModalContainer />
      <RecoveryPasswordModalContainer />
      <SignUpFinishContainer />
      <LogoutModalContainer />
      <GoodByeModalContainer />
      <LoginContainer isOnline={isOnline} history={appHistory} />
    </>
  );
  if (isSSR()) {
    const ssrHistory = createMemoryHistory();
    // @ts-ignore
    ssrHistory.push(SSRRout);
    return (
      <Router history={ssrHistory}>
        {renderRouter}
        <LoginContainer isOnline={isOnline} history={ssrHistory} />
      </Router>
    );
  }

  return (
    <Router history={appHistory}>
      {renderRouter}
      <LoginContainer isOnline={isOnline} history={appHistory} />
    </Router>
  );
};
