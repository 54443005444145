import React from 'react';
import { Carousel } from 'react-bootstrap';
import { IVideoCarouselProps } from './VideoCarousel.types';
import poster from '../../img/poster.svg';

export const VideoCarousel: React.FC<IVideoCarouselProps> = ({ videoList }: IVideoCarouselProps) => (
  <Carousel>
    {videoList.map(item => (
      <Carousel.Item key={item}>
        <video controls loop muted playsInline crossOrigin="anonymous" poster={poster}>
          <source src={item} type="video/mp4" />
        </video>
      </Carousel.Item>
    ))}
  </Carousel>
);
