import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { useTranslation } from 'react-i18next';
import { PageText } from '../../ui-components/PageText/PageText';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { IModalPlanExpiredTypesProps } from './ModalPlanExpired.types';

const ModalPlanExpired: React.FC<IModalPlanExpiredTypesProps> = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} size="sm" onHide={() => {}}>
      <ModalHeader closeButton onHide={() => onHide(true)}>
        <Modal.Title>{t('modalPlanExpired.title')}</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <PageText variant="p3">{t('modalPlanExpired.text1')}</PageText>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" as={LinkWrapper} to="/" className="linkBtn" onClick={() => onHide(true)}>
          {t('modalPlanExpired.homeLink')}
        </Button>
        <Button variant="secondary" as={LinkWrapper} to="/extend-offers" onClick={() => onHide()}>
          {t('modalPlanExpired.planExtendBtn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPlanExpired;
