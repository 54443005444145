import React from 'react';
import { Accordion, Card, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ICockpitCategoriesAccordionProps } from './CockpitCategoriesAccordion.types';
import styles from './CockpitCategoriesAccordion.module.css';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import apptype from '../../utils/apptype';

const CockpitCategoriesAccordion: React.FC<ICockpitCategoriesAccordionProps> = ({
  isLogged
}: ICockpitCategoriesAccordionProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.cardCategoriesMobile}>
      <Container>
        <Row>
          <Col>
            <div className={styles.cardCategoriesList}>
              <Accordion className={styles.cardCategoriesItem}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3Danimations">
                    {t('myCockpitCategories.title.3Danimations')}
                    <i className="icon-arrow" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3Danimations">
                    <Card.Body>
                      <Card.Text>
                        {t(
                          isLogged
                            ? 'myCockpitCategories.subtitle.3Danimations'
                            : 'myCockpitCategories.subtitleDemo.3Danimations'
                        )}
                      </Card.Text>
                      <LinkWrapper className="linkBtn" to="/drivingskills">
                        {t('myCockpitCategories.weiter')}
                        <i className="icon-arrow" />
                      </LinkWrapper>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className={styles.cardCategoriesItem}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="ebike">
                    {t(`myCockpitCategories.title.${apptype.type}`)}
                    <i className="icon-arrow" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="ebike">
                    <Card.Body>
                      <Card.Text>
                        {t(
                          isLogged
                            ? `myCockpitCategories.subtitle.${apptype.type}`
                            : `myCockpitCategories.subtitleDemo.${apptype.type}`
                        )}
                      </Card.Text>
                      <LinkWrapper className="linkBtn" to={`/${apptype.type}`}>
                        {t('myCockpitCategories.weiter')}
                        <i className="icon-arrow" />
                      </LinkWrapper>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className={styles.cardCategoriesItem}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="ecodrive">
                    {t('myCockpitCategories.title.ecodrive')}
                    <i className="icon-arrow" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="ecodrive">
                    <Card.Body>
                      <Card.Text>
                        {t(
                          isLogged
                            ? 'myCockpitCategories.subtitle.ecodrive'
                            : 'myCockpitCategories.subtitleDemo.ecodrive'
                        )}
                      </Card.Text>
                      <LinkWrapper className="linkBtn" to="/ecodrive">
                        {t('myCockpitCategories.weiter')}
                        <i className="icon-arrow" />
                      </LinkWrapper>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className={styles.cardCategoriesItem}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="ask">
                    {t('myCockpitCategories.title.ask')}
                    <i className="icon-arrow" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="ask">
                    <Card.Body>
                      <Card.Text>
                        {t(
                          isLogged
                            ? `myCockpitCategories.subtitle.ask${apptype.type}`
                            : `myCockpitCategories.subtitleDemo.ask${apptype.type}`
                        )}
                      </Card.Text>
                      <LinkWrapper className="linkBtn" to="/questions">
                        {t('myCockpitCategories.weiter')}
                        <i className="icon-arrow" />
                      </LinkWrapper>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className={styles.cardCategoriesItem}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="exam">
                    {t('myCockpitCategories.title.exam')}
                    <i className="icon-arrow" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="exam">
                    <Card.Body>
                      <Card.Text>
                        {t(isLogged ? 'myCockpitCategories.subtitle.exam' : 'myCockpitCategories.subtitleDemo.exam')}
                      </Card.Text>
                      <LinkWrapper className="linkBtn" to="/exam">
                        {t('myCockpitCategories.weiter')}
                        <i className="icon-arrow" />
                      </LinkWrapper>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className={styles.cardCategoriesItem}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="theory">
                    {t('myCockpitCategories.title.theory')}
                    <i className="icon-arrow" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="theory">
                    <Card.Body>
                      <Card.Text>
                        {t(
                          isLogged ? 'myCockpitCategories.subtitle.theory' : 'myCockpitCategories.subtitleDemo.theory'
                        )}
                      </Card.Text>
                      <LinkWrapper className="linkBtn" to="/theory">
                        {t('myCockpitCategories.weiter')}
                        <i className="icon-arrow" />
                      </LinkWrapper>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitCategoriesAccordion;
