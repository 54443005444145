import React from 'react';
// import { Col, Row, Container } from 'react-bootstrap';
// import styles from './Ads.module.css';

const Ads: React.FC = () => {
  return <div />;

  // return (
  //   <div className={styles.ads}>
  //     <Container>
  //       <Row>
  //         <Col>
  //           <div className={styles.adsContent}>
  //             <h3 className={styles.adsText}>Ads</h3>
  //           </div>
  //         </Col>
  //       </Row>
  //     </Container>
  //   </div>
  // );
};

export default Ads;
