import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Container, Jumbotron } from 'react-bootstrap';
import Avatar from '../../ui-components/Avatar/Avatar';
import { ICockpitBottomPanelProps } from './CockpitBottomPanel.types';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { P3 } from '../../ui-components/P3/P3';
import styles from './CockpitBottomPanel.module.css';
import { selectIsPlanExpired } from '../../reducer/user.selectors';
import { API_DATE_FORMAT } from '../../constants/api';
import { getPlanDateCountLabel } from '../../utils/utils';

const CockpitBottomPanel: React.FC<ICockpitBottomPanelProps> = ({
  activationPlaneDateTime,
  expirePlaneDateTime,
  drivingInstructor
}: ICockpitBottomPanelProps) => {
  const { t } = useTranslation();
  const availablePlanDays = getPlanDateCountLabel(expirePlaneDateTime);
  const isExpired = useSelector(selectIsPlanExpired);

  if (isExpired) {
    return (
      <div className={styles.cockpitBottomPanel}>
        <Container>
          <Row>
            <Col>
              <div className={styles.bottomPanelList} id="cockpitBottomPanel">
                <Jumbotron className={styles.jumbotron}>
                  <div className={styles.jumbotronBox}>
                    <h3 className="h3">{t('cockpitBottomPanel.thirdBlock.title')}</h3>
                  </div>
                  <div className={styles.jumbotronBox}>
                    <div className={styles.daysCount}>
                      <div className={styles.daysValue}>{availablePlanDays}</div>
                      <div className={styles.daysText}>{t('cockpitBottomPanel.thirdBlock.days')}</div>
                    </div>
                  </div>
                  <div className={styles.jumbotronBoxDate}>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.jumbotronBoxItem}>
                        <div className={styles.dateItem}>
                          <div className={styles.dateItemLabel}>{t('cockpitBottomPanel.thirdBlock.activation')}</div>
                          <div className={styles.dateItemText}>
                            {moment(activationPlaneDateTime, API_DATE_FORMAT).format('D MMMM YYYY')}
                          </div>
                        </div>
                        <div className={styles.dateItem}>
                          <div className={styles.dateItemLabel}>{t('cockpitBottomPanel.thirdBlock.expiry')}</div>
                          <div className={styles.dateItemText}>
                            {moment(expirePlaneDateTime, API_DATE_FORMAT).format('D MMMM YYYY')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.jumbotronBoxBtn}>
                      <Button variant="primary" as={LinkWrapper} to="/extend-offers">
                        {t('cockpitBottomPanel.thirdBlock.extendButton')}
                      </Button>
                    </div>
                  </div>
                </Jumbotron>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className={styles.cockpitBottomPanel}>
      <Container>
        <Row>
          <Col>
            <div className={styles.bottomPanelList}>
              <Jumbotron className={styles.jumbotron} hidden>
                <div className={styles.jumbotronBox}>
                  <h3 className="h3">{t('cockpitBottomPanel.secondBlock.title')}</h3>
                </div>
                {drivingInstructor && (
                  <>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.instructorContent}>
                        <Avatar alt={drivingInstructor.fullName} />
                        <div className={styles.instructorInfo}>
                          <div className={styles.instructorName}>{drivingInstructor.fullName}</div>
                          <div className={styles.instructorSc}>{drivingInstructor.school}</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.jumbotronBox}>
                      <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.address')}</p>
                      <p className={styles.jumbotronValue}>{drivingInstructor.address}</p>
                    </div>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.jumbotronBoxItem}>
                        <div className={styles.dateItem}>
                          <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.phone')}</p>
                          <p className={styles.jumbotronValue}>{drivingInstructor.phone}</p>
                        </div>
                        <div className={styles.dateItem}>
                          <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.mobile')}</p>
                          <p className={styles.jumbotronValue}>{drivingInstructor.mobile}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.jumbotronBox}>
                      <p className={styles.jumbotronLabel}>{t('cockpitBottomPanel.secondBlock.email')}</p>
                      <p className={styles.jumbotronValue}>{drivingInstructor.email}</p>
                    </div>
                  </>
                )}
                {!drivingInstructor && (
                  <div className={styles.jumbotronFooter}>
                    <div className={styles.jumbotronBox}>
                      <div className={styles.jumbotronIconBox}>
                        <i className="icon-driving-school" />
                      </div>
                      <P3 className={styles.jumbotronPlaceholder}>{t('cockpitBottomPanel.secondBlock.placeholder')}</P3>
                    </div>
                    <div className={styles.jumbotronBoxBtn}>
                      <Button variant="secondary" as={LinkWrapper} to="/school">
                        {t('cockpitBottomPanel.secondBlock.selectInstructorBtn')}
                      </Button>
                    </div>
                  </div>
                )}
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.jumbotronBox}>
                  <h3 className="h3">{t('cockpitBottomPanel.thirdBlock.title')}</h3>
                </div>
                <div className={styles.jumbotronBox}>
                  <div className={styles.daysCount}>
                    <div className={styles.daysValue}>{availablePlanDays}</div>
                    <div className={styles.daysText}>{t('cockpitBottomPanel.thirdBlock.days')}</div>
                  </div>
                </div>
                <div className={styles.jumbotronBoxDate}>
                  <div className={styles.jumbotronBox}>
                    <div className={styles.jumbotronBoxItem}>
                      <div className={styles.dateItem}>
                        <div className={styles.dateItemLabel}>{t('cockpitBottomPanel.thirdBlock.activation')}</div>
                        <div className={styles.dateItemText}>
                          {moment(activationPlaneDateTime, API_DATE_FORMAT).format('D MMMM YYYY')}
                        </div>
                      </div>
                      <div className={styles.dateItem}>
                        <div className={styles.dateItemLabel}>{t('cockpitBottomPanel.thirdBlock.expiry')}</div>
                        <div className={styles.dateItemText}>
                          {moment(expirePlaneDateTime, API_DATE_FORMAT).format('D MMMM YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.jumbotronBoxBtn}>
                    <Button variant="primary" as={LinkWrapper} to="/extend-offers">
                      {t('cockpitBottomPanel.thirdBlock.extendButton')}
                    </Button>
                  </div>
                </div>
              </Jumbotron>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitBottomPanel;
