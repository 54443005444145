import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../reducer';
import CockpitTopPanel from '../components/CockpitTopPanel/CockpitTopPanel';
import CockpitCategories from '../components/CockpitCategories/CockpitCategories';
import CockpitBottomPanel from '../components/CockpitBottomPanel/CockpitBottomPanel';
import CockpitLoggedOffline from '../components/CockpitLoggedOffline/CockpitLoggedOffline';
import { GET_USER_EXAM_RESULTS_REQUEST } from '../actions/user.action';
import { Request, RequestStatusType } from '../reducer/fetch.types';
import { GET_TOPICS_REQUEST } from '../actions/topic.action';
import { PENDING } from '../constants/store.constants';
import { Loader } from '../ui-components/Loader/Loader';
import { selectIsPlanExpired, selectLanguage } from '../reducer/user.selectors';
import { IUserCategoryList } from '../reducer/category.types';
import { GET_CATEGORY_REQUEST } from '../actions/category.action';
import { selectIsOfflineMode } from '../reducer/auth.selectors';
import CockpitCategoriesAccordion from '../components/CockpitCategoriesAccordion/CockpitCategoriesAccordion';
import styles from './CockpitLoggedContainer.module.css';

const CockpitLoggedContainer: React.FC = () => {
  const dispatch = useDispatch();
  const userState = useSelector(({ user }: IRootState) => user.profile);
  const examsResults = useSelector(({ user }: IRootState) => user.examResults);
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const createExamRequest: Request = useSelector(({ exam }: IRootState) => exam.createExamRequest);
  const userCategory: IUserCategoryList = useSelector(({ category }: IRootState) => category.category);
  const language = useSelector(selectLanguage);
  const userStatistics = useSelector(({ user }: IRootState) => user.currentCategoryStatistics);
  const categoryRequestStatus: RequestStatusType = useSelector(({ category }: IRootState) => category.categoryRequest);
  const isOffline = useSelector(selectIsOfflineMode);
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const isExpired = useSelector(selectIsPlanExpired);

  useEffect(() => {
    dispatch(GET_TOPICS_REQUEST({ category: currentCategory, language, cockpitPage: true }));
  }, [dispatch, currentCategory, language]);

  useEffect(() => {
    dispatch(GET_USER_EXAM_RESULTS_REQUEST({ currentCategory }));
  }, [dispatch, currentCategory]);

  useEffect(() => {
    if (!userCategory) {
      dispatch(GET_CATEGORY_REQUEST());
    }
  }, [dispatch, userCategory]);

  if (isOffline) {
    return (
      <>
        <CockpitLoggedOffline />
        <Loader show={createExamRequest.status === PENDING || categoryRequestStatus === PENDING} />
      </>
    );
  }

  return (
    <>
      {!isExpired && (
        <>
          <CockpitTopPanel
            userName={`${userState.firstName} ${userState.lastName}`}
            bestResult={examsResults.bestResult}
            selectedCategory={currentCategory}
            expirePlaneDateTime={userState.expirePlaneDateTime}
          />
          <CockpitCategories isLogged={isLogged} userStatistics={userStatistics} />
          <div className={styles.mobileCategories}>
            <CockpitCategoriesAccordion isLogged={isLogged} />
          </div>
        </>
      )}
      <CockpitBottomPanel
        activationPlaneDateTime={userState.activationPlaneDateTime}
        lastExams={examsResults.lastExams}
        expirePlaneDateTime={userState.expirePlaneDateTime}
        drivingInstructor={userState.drivingInstructor}
        userStatistics={userStatistics}
      />
      <Loader show={createExamRequest.status === PENDING || categoryRequestStatus === PENDING} />
    </>
  );
};

export default CockpitLoggedContainer;
