import React, { useEffect } from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../reducer';
import { Loader } from '../ui-components/Loader/Loader';
import locale, { defaultLanguage } from '../locales/locale';
import {
  SET_USER_LANGUAGE_REQUEST,
  SET_USER_QUESTION_LANGUAGE_SUCCESS,
  SET_USER_LANGUAGE_SUCCESS
} from '../actions/user.action';
import { selectIsPlanExpired, selectLanguage } from '../reducer/user.selectors';
import { selectIsOfflineMode } from '../reducer/auth.selectors';
import { rsPostfixList } from '../constants/rsPostfix';
import { scrollToAnchor } from '../utils/utils';
import { setMomentLocale } from '../utils/moment';
import { RouteConfigContext, LanguageContext } from '../context';
import { RoutesTypes } from './routes.types';
import { LanguageType } from '../types';

interface IPrivateRouteProps {
  demoPathName?: string;
  routeProps: RouteChildrenProps<{ locale?: LanguageType }>;
  component: React.ComponentType<RouteChildrenProps>;
  loaderState: boolean;
  withLoader?: boolean;
  renderAfterLoad?: boolean;
  onlyForDemo?: boolean;
  route: RoutesTypes;
  hideExpired?: boolean;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  demoPathName,
  routeProps,
  component,
  loaderState,
  onlyForDemo = false,
  hideExpired = false,
  route
}: IPrivateRouteProps) => {
  const { t } = useTranslation();
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const { history } = useReactRouter();
  const Comp = component;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentLanguage = useSelector(selectLanguage);
  const isOffline = useSelector(selectIsOfflineMode);
  const isExpired = useSelector(selectIsPlanExpired);
  const language = (routeProps.match && routeProps.match.params && routeProps.match.params.locale) || undefined;

  useEffect(() => {
    scrollToAnchor('root', { behavior: 'auto' });
  }, []);

  useEffect(() => {
    if (!isLogged && demoPathName && !loaderState) {
      history.replace(`/${currentLanguage + demoPathName}`);
    }
    // eslint-disable-next-line
  }, [isLogged, history, demoPathName, loaderState]);

  useEffect(() => {
    setMomentLocale(language);
    if (!language) {
      i18n.changeLanguage(defaultLanguage);
      dispatch(SET_USER_LANGUAGE_SUCCESS({ language: defaultLanguage }));
    } else if (currentLanguage !== language) {
      i18n.changeLanguage(language);
      if (!isOffline) {
        dispatch(SET_USER_LANGUAGE_REQUEST({ language }));
        dispatch(SET_USER_QUESTION_LANGUAGE_SUCCESS({ language }));
      }
    }
  }, [history, language, i18n, dispatch, currentLanguage, isOffline]);

  useEffect(() => {
    const setDeviceOnlineCB = () => {
      dispatch(SET_USER_LANGUAGE_REQUEST({ language }));
    };
    if (isOffline) {
      dispatch(SET_USER_QUESTION_LANGUAGE_SUCCESS({ language }));
      window.addEventListener('online', setDeviceOnlineCB, { once: true });
    }
    return () => {
      window.removeEventListener('online', setDeviceOnlineCB);
    };
  }, [language, history, dispatch, currentLanguage, isOffline]);

  useEffect(() => {
    const rs = (window as any).$rs;
    const langKeyList = Object.keys(locale);
    let stopBtn;

    if (rs) {
      rsPostfixList.forEach(postfix => {
        langKeyList.forEach(lngKey => {
          stopBtn = rs.get(`#readspeaker_button_${lngKey}${postfix} .rsbtn_stop`);
          if (stopBtn && stopBtn.click) {
            stopBtn.click();
          }
        });
      });
    }
  }, [history]);

  if (isLogged && onlyForDemo) {
    return <Redirect to="/" />;
  }

  if (isLogged && isExpired && hideExpired) {
    return <Redirect to={`/${currentLanguage}/mycockpit`} />;
  }

  return (
    <>
      <Helmet title={t(`seo.title`)} description={t(`seo.description`)} />
      {!loaderState && (
        <LanguageContext.Provider value={language}>
          <RouteConfigContext.Provider value={route}>
            <Comp history={routeProps.history} match={routeProps.match} location={routeProps.location} />
          </RouteConfigContext.Provider>
        </LanguageContext.Provider>
      )}
      <Loader show={loaderState} />
    </>
  );
};
