import { string, object, number, InferType, array } from 'yup';

export interface IGetTheoryByKey {
  language: string;
}

export const ChaptersAttributesSchema = object().shape({
  page: number(),
  title: string()
});

export type TChapters = InferType<typeof ChaptersAttributesSchema>;

export const PagesAttributesSchema = object().shape({
  img: array().of(string()),
  page: number(),
  text: string()
});

export type TPages = InferType<typeof PagesAttributesSchema>;

export const RegistersAttributesSchema = object().shape({
  name: string(),
  letter: string(),
  page: number()
});

export type TRegisters = InferType<typeof RegistersAttributesSchema>;

export interface ITheory {
  chapters: TChapters[];
  pages: TPages[];
  registers: TRegisters[];
}
