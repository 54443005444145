import React, { useCallback, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { ITopicProgressProps } from './TopicProgress.types';
import { IUserAnswer } from '../../reducer/user.types';
import { TopicsAttributes } from '../../reducer/topic.types';
import styles from './TopicProgress.module.css';
import QuestionChooseModalContainer from '../../containers/QuestionChooseModalContainer/QuestionChooseModalContainer';

const getTopicProgress = (topic: TopicsAttributes, userAnswers: IUserAnswer) => {
  // is topic
  let checkedCount: number = 0;
  let correctCount: number = 0;

  if (topic.id > 0) {
    // has no answers, yet
    if (!userAnswers[topic.id]) {
      return {
        status: -1,
        progress: 0
      };
    }

    const answersKeyList: string[] = Object.keys(userAnswers[topic.id]);

    answersKeyList.forEach(answerKey => {
      if (typeof userAnswers[topic.id][answerKey].correct === 'boolean') {
        checkedCount += 1;
        if (userAnswers[topic.id][answerKey].correct) {
          correctCount += 1;
        }
      }
    });
  } else {
    // is all!
    const topicIds = Object.keys(userAnswers);
    topicIds.forEach(topicId => {
      const answersKeyList: string[] = Object.keys(userAnswers[topicId]);

      answersKeyList.forEach(answerKey => {
        if (typeof userAnswers[topicId][answerKey].correct === 'boolean') {
          checkedCount += 1;
          if (userAnswers[topicId][answerKey].correct) {
            correctCount += 1;
          }
        }
      });
    });
  }

  return {
    status: checkedCount ? correctCount / checkedCount : -1,
    progress: checkedCount ? (checkedCount / topic.questionsCount) * 100 : 0
  };
};

const TopicProgress: React.FC<ITopicProgressProps> = ({
  topic,
  userAnswers,
  currentCategory,
  currentLanguage,
  onQuestionChange
}: ITopicProgressProps) => {
  const [showWin, setShowWin] = useState(false);

  const changeModalView = useCallback(() => {
    setShowWin(!showWin);
  }, [showWin, setShowWin]);

  const topicstatus = getTopicProgress(topic, userAnswers);

  return (
    <div
      className={styles.progressContent}
      title={`${((topicstatus.progress * topic.questionsCount) / 100).toFixed(0)} / ${topic.questionsCount}`}
    >
      <ProgressBar now={topicstatus.progress} onClick={changeModalView} />
      <div
        title={`${topicstatus.status > -1 ? (topicstatus.status * 100).toFixed(0) : '0'} %`}
        className={classNames(styles.status, {
          [styles.empty]: topicstatus.status === -1,
          [styles.bad]: topicstatus.status >= 0.0 && topicstatus.status < 0.4,
          [styles.middle]: topicstatus.status >= 0.4 && topicstatus.status < 1.0,
          [styles.good]: topicstatus.status === 1.0
        })}
      />
      <QuestionChooseModalContainer
        onQuestionChange={onQuestionChange}
        defaultRoute={`/${currentLanguage}/questions/${currentCategory}/${topic.id < 0 ? 'all' : topic.id}`}
        show={showWin}
        onHide={changeModalView}
        topicId={topic.id < 0 ? 'all' : topic.id}
      />
    </div>
  );
};

export default TopicProgress;
