import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { ITheoryTopicProps } from './TheoryTopic.types';
import styles from './TheoryTopic.module.css';

export const TheoryTopic: React.FC<ITheoryTopicProps> = ({
  onChangePathname,
  content,
  pathname
}: ITheoryTopicProps) => {
  const handleChange = useCallback(() => {
    onChangePathname(pathname);
  }, [onChangePathname, pathname]);

  return (
    <div className={styles.topicListItem}>
      <Button className={styles.topicsListItemBtn} variant="link" onClick={handleChange}>
        {content}
        <i className="icon-arrow" />
      </Button>
    </div>
  );
};
