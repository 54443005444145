import React, { useCallback } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Nav, Form, Button } from 'react-bootstrap';
import NavLinkWrapper from '../../ui-components/NavLinkWrapper/NavLinkWrapper';
import { IHeaderMenuProps } from './HeaderMenu.types';
import DemoFlag from '../DemoFlag/DemoFlag';
import { lngList } from '../../locales/locale';
import Translate from '../Translate/Translate';
import styles from './HeaderMenu.module.css';
import { triggerMenuCollapse } from '../../utils/utils';
import apptype from '../../utils/apptype';

const HeaderMenu: React.FC<IHeaderMenuProps> = ({
  isLogged,
  routeProps,
  onLoginClick,
  onLogoutClick,
  isDemo
}: IHeaderMenuProps) => {
  const { t } = useTranslation();

  const handleLoginBtnClick = useCallback(() => {
    onLoginClick();
    triggerMenuCollapse();
  }, [onLoginClick]);

  const handleLogoutBtnClick = useCallback(() => {
    onLogoutClick();
    triggerMenuCollapse();
  }, [onLogoutClick]);

  return (
    <>
      <Nav className="mr-auto">
        <Nav.Link as={NavLinkWrapper} to="/drivingskills" href="#theory" hidden={!isLogged} active={false}>
          {t('header.drivingskills')}
        </Nav.Link>
        <Nav.Link
          as={NavLinkWrapper}
          to={`/${apptype.type}`}
          href={`#${apptype.type}`}
          hidden={!isLogged}
          active={false}
        >
          {t(`header.${apptype.type}`)}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/ecodrive" href="#ecodrive" hidden={!isLogged} active={false}>
          {t('header.ecodrive')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/questions" href="#questions" hidden={!isLogged} active={false}>
          {t('header.ask')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/theory" href="#theory" hidden={!isLogged} active={false}>
          {t('header.theory')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/exam" href="#exam" hidden={!isLogged} active={false}>
          {t('header.exam')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/contacts" href="#contacts" hidden={!isLogged} active={false}>
          {t('header.contact')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/faq" href="#faq" hidden={!isLogged} active={false}>
          {t('header.faq')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/anleitung" href="#" hidden active={false}>
          {t('header.manual')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/offers" href="#offers" hidden={isLogged} active={false}>
          {t('header.deals')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/code" href="#code" hidden={isLogged} active={false}>
          {t('header.vipcode')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/about" href="#about" hidden={isLogged} active={false}>
          {t('header.about')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/faq" href="#faq" hidden={isLogged} active={false}>
          {t('header.faq')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/instructor" href="#fahrlehrer" hidden active={false}>
          {t('header.drivingInstructor')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/contacts" href="#contacts" hidden={isLogged} active={false}>
          {t('header.contact')}
        </Nav.Link>
        <DemoFlag isHidden={!isDemo} />
        {isLogged && (
          <Nav.Link
            className={cx(styles.loginBtnItem, styles.loginBtnMobile)}
            as={NavLinkWrapper}
            href="#mycockpit"
            to="/mycockpit"
            active={false}
          >
            {t('header.cockpit')}
          </Nav.Link>
        )}
      </Nav>
      <Nav className={styles.loginBtnGroup} hidden={!isLogged}>
        <Nav.Link
          className={cx(styles.loginBtnItem, styles.desktop)}
          as={NavLinkWrapper}
          href="#mycockpit"
          to="/mycockpit"
          active={false}
        >
          {t('header.cockpit')}
        </Nav.Link>
        <Form>
          <Button className={styles.logoutBtn} variant="secondary" onClick={handleLogoutBtnClick}>
            {t('app.btn.logout')}
          </Button>
        </Form>
        <Form>
          <Button className={styles.logoutBtnMobile} variant="link" onClick={handleLogoutBtnClick}>
            {t('app.btn.logout')}
          </Button>
        </Form>
      </Nav>
      <Nav hidden={isLogged}>
        <Form>
          <Button className={styles.loginBtn} variant="secondary" onClick={handleLoginBtnClick}>
            {t('app.btn.login')}
          </Button>
        </Form>
      </Nav>
      <Nav hidden={isLogged}>
        <Form>
          <Button className={styles.loginBtnMobile} variant="link" onClick={handleLoginBtnClick}>
            {t('app.btn.login')}
          </Button>
        </Form>
      </Nav>
      <Nav className={styles.langIconGroup}>
        {Object.keys(lngList).map((lng: string) => {
          return (
            <Nav.Link key={lng} href="#">
              <Translate lng={lngList[lng].shortName} icon={lngList[lng].icon} routeProps={routeProps} />
            </Nav.Link>
          );
        })}
      </Nav>
    </>
  );
};

export default HeaderMenu;
