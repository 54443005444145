import { takeEvery, select, put, all } from 'redux-saga/effects';
import { firebaseApp } from '../config/firebase.config';
import { IRootState } from '../reducer';
import {
  ADD_ANSWER_FAILURE,
  ADD_ANSWER_REQUEST,
  ADD_ANSWER_SUCCESS,
  CLEAR_ANSWER_BY_TOPIC_FAILURE,
  CLEAR_ANSWER_BY_TOPIC_SUCCESS,
  CLEAR_CURRENT_ANSWER_REQUEST,
  GET_ANSWER_FROM_FIREBASE_FAILURE,
  GET_ANSWER_FROM_FIREBASE_SUCCESS,
  CLEAR_CURRENT_ANSWER_SUCCESS,
  CLEAR_CURRENT_ANSWER_FAILURE
} from '../actions/answer.action';
import { getAnswerList, saveAnswerByTopic, clearAnswerByTopic, clearAnswerByAnswerId } from './answer.api';
import { IAnswerPayload } from '../containers/QuestionCardContainer/QuestionCardContainer.types';
import { getFBCategoryName } from '../utils/utils';

const getCategory = ({ user }: IRootState) => user.profile.currentCategory;

function* setAnswerToFirebase(action) {
  try {
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    const { currentUser } = firebaseApp.auth();
    const userId = currentUser ? currentUser.uid : '';

    yield saveAnswerByTopic({
      userId,
      categoryForQuery,
      action
    });
    yield put(ADD_ANSWER_SUCCESS());
  } catch (e) {
    yield put(ADD_ANSWER_FAILURE(e.message));
  }
}

function* clearUserAnswersByTopic(action) {
  try {
    const { currentUser } = firebaseApp.auth();
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);

    yield clearAnswerByTopic(currentUser ? currentUser.uid : '', categoryForQuery, action.payload.topic.toString());

    yield put(CLEAR_ANSWER_BY_TOPIC_SUCCESS());
  } catch (e) {
    yield put(CLEAR_ANSWER_BY_TOPIC_FAILURE(e.message));
  }
}

function* getUserAnswersFromFirebase() {
  try {
    const { currentUser } = firebaseApp.auth();
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    const answers = yield getAnswerList(currentUser ? currentUser.uid : '', categoryForQuery);

    yield put(GET_ANSWER_FROM_FIREBASE_SUCCESS({ answers }));
  } catch (e) {
    yield put(GET_ANSWER_FROM_FIREBASE_FAILURE(e.message));
  }
}

function* onAnswerChange(action) {
  try {
    const { answerId, question, userAnswer } = action.payload;
    const answerPayload: IAnswerPayload = {
      questionId: question.id,
      qTopic: question.themeid
    };

    if (!userAnswer) {
      answerPayload.answer = {
        selected: [answerId]
      };
    } else {
      const selectedAnswers: number[] = [...userAnswer.selected];
      if (selectedAnswers.indexOf(answerId) >= 0) {
        selectedAnswers.splice(selectedAnswers.indexOf(answerId), 1);
      } else {
        selectedAnswers.push(answerId);
      }
      answerPayload.answer = {
        selected: selectedAnswers
      };
    }

    yield put(ADD_ANSWER_REQUEST(answerPayload));
  } catch (e) {
    console.log(e.message);
  }
}

function* clearCurrentAnswer({ payload: { question } }) {
  try {
    const { currentUser } = firebaseApp.auth();
    const currentCategory = yield select(getCategory);
    const categoryForQuery = getFBCategoryName(currentCategory);
    yield clearAnswerByAnswerId(currentUser ? currentUser.uid : '', categoryForQuery, question.id);
    yield put(CLEAR_CURRENT_ANSWER_SUCCESS({ themeId: question.themeid, questionId: question.id }));
  } catch (e) {
    yield put(CLEAR_CURRENT_ANSWER_FAILURE(e.message));
  }
}

function* answerSaga() {
  yield all([
    takeEvery('CHANGE_ANSWER', onAnswerChange),
    takeEvery('ADD_ANSWER_REQUEST', setAnswerToFirebase),
    takeEvery('CLEAR_ANSWER_BY_TOPIC_REQUEST', clearUserAnswersByTopic),
    takeEvery(CLEAR_CURRENT_ANSWER_REQUEST, clearCurrentAnswer),
    takeEvery('GET_ANSWER_FROM_FIREBASE_REQUEST', getUserAnswersFromFirebase)
  ]);
}

export default answerSaga;
